import { PerformanceMetricLabels } from '../../../api/models/performance';

export const baseData = {
  isLoading: false,
  data: {
    SV_VALUE: {
      datasetLabel: PerformanceMetricLabels.SV_VALUE,
      dataset: [
        {
          date: '2025-01-01',
          value: 883.33,
        },
        {
          date: '2025-02-01',
          value: 5396.79,
        },
        {
          date: '2025-03-01',
          value: 9131.54,
        },
        {
          date: '2025-04-01',
          value: 13254.83,
        },
        {
          date: '2025-05-01',
          value: 23520.0,
        },
        {
          date: '2025-06-01',
          value: 28579.09,
        },
        {
          date: '2025-07-01',
          value: 36270.0,
        },
        {
          date: '2025-08-01',
          value: 40480.0,
        },
        {
          date: '2025-09-01',
          value: 43601.25,
        },
        {
          date: '2025-10-01',
          value: 60820.0,
        },
        {
          date: '2025-11-01',
          value: 72037.27,
        },
        {
          date: '2025-12-01',
          value: 84597.5,
        },
      ],
    },
    CONVERSIONS: {
      datasetLabel: PerformanceMetricLabels.CONVERSIONS,
      dataset: [
        {
          date: '2025-01-01',
          value: 1.26,
        },
        {
          date: '2025-02-01',
          value: 1.94,
        },
        {
          date: '2025-03-01',
          value: 2.23,
        },
        {
          date: '2025-04-01',
          value: 2.3,
        },
        {
          date: '2025-05-01',
          value: 4.03,
        },
        {
          date: '2025-06-01',
          value: 8.0,
        },
        {
          date: '2025-07-01',
          value: 9.0,
        },
        {
          date: '2025-08-01',
          value: 8.4,
        },
        {
          date: '2025-09-01',
          value: 8.89,
        },
        {
          date: '2025-10-01',
          value: 11.0,
        },
        {
          date: '2025-11-01',
          value: 14.5,
        },
        {
          date: '2025-12-01',
          value: 17.0,
        },
      ],
    },
    UNIQUE_VISITORS: {
      datasetLabel: PerformanceMetricLabels.UNIQUE_VISITORS,
      dataset: [
        {
          date: '2025-01-01',
          value: 10.63,
        },
        {
          date: '2025-02-01',
          value: 16.45,
        },
        {
          date: '2025-03-01',
          value: 18.73,
        },
        {
          date: '2025-04-01',
          value: 18.85,
        },
        {
          date: '2025-05-01',
          value: 33.34,
        },
        {
          date: '2025-06-01',
          value: 53.29,
        },
        {
          date: '2025-07-01',
          value: 69.04,
        },
        {
          date: '2025-08-01',
          value: 70.23,
        },
        {
          date: '2025-09-01',
          value: 73.83,
        },
        {
          date: '2025-10-01',
          value: 112.14,
        },
        {
          date: '2025-11-01',
          value: 119.8,
        },
        {
          date: '2025-12-01',
          value: 206.33,
        },
      ],
    },
    GBP_VIDEOS: {
      datasetLabel: PerformanceMetricLabels.GBP_VIDEOS,
      dataset: [
        {
          date: '2025-01-01',
          value: 3,
        },
        {
          date: '2025-02-01',
          value: 6,
        },
        {
          date: '2025-03-01',
          value: 9,
        },
        {
          date: '2025-04-01',
          value: 12,
        },
        {
          date: '2025-05-01',
          value: 15,
        },
        {
          date: '2025-06-01',
          value: 18,
        },
        {
          date: '2025-07-01',
          value: 21,
        },
        {
          date: '2025-08-01',
          value: 24,
        },
        {
          date: '2025-09-01',
          value: 27,
        },
        {
          date: '2025-10-01',
          value: 30,
        },
        {
          date: '2025-11-01',
          value: 33,
        },
        {
          date: '2025-12-01',
          value: 36,
        },
      ],
    },
    LIVE_VIDEOS: {
      datasetLabel: PerformanceMetricLabels.LIVE_VIDEOS,
      dataset: [
        {
          date: '2025-01-01',
          value: 3.0,
        },
        {
          date: '2025-02-01',
          value: 7.0,
        },
        {
          date: '2025-03-01',
          value: 11.0,
        },
        {
          date: '2025-04-01',
          value: 13.0,
        },
        {
          date: '2025-05-01',
          value: 19.0,
        },
        {
          date: '2025-06-01',
          value: 20.0,
        },
        {
          date: '2025-07-01',
          value: 23.0,
        },
        {
          date: '2025-08-01',
          value: 27.0,
        },
        {
          date: '2025-09-01',
          value: 30.0,
        },
        {
          date: '2025-10-01',
          value: 35.0,
        },
        {
          date: '2025-11-01',
          value: 44.67,
        },
        {
          date: '2025-12-01',
          value: 53.0,
        },
      ],
    },
    VIDEOS: {
      datasetLabel: PerformanceMetricLabels.VIDEOS,
      dataset: [
        {
          date: '2025-01-01',
          value: 6.46,
        },
        {
          date: '2025-02-01',
          value: 18.28,
        },
        {
          date: '2025-03-01',
          value: 18.81,
        },
        {
          date: '2025-04-01',
          value: 25.0,
        },
        {
          date: '2025-05-01',
          value: 36.4,
        },
        {
          date: '2025-06-01',
          value: 41.0,
        },
        {
          date: '2025-07-01',
          value: 45.0,
        },
        {
          date: '2025-08-01',
          value: 55.0,
        },
        {
          date: '2025-09-01',
          value: 60.0,
        },
        {
          date: '2025-10-01',
          value: 66.29,
        },
        {
          date: '2025-11-01',
          value: 73.0,
        },
        {
          date: '2025-12-01',
          value: 80.0,
        },
      ],
    },
    VIDEOS_PLAYED: {
      datasetLabel: PerformanceMetricLabels.VIDEOS_PLAYED,
      dataset: [
        {
          date: '2025-01-01',
          value: 21.53,
        },
        {
          date: '2025-02-01',
          value: 46.91,
        },
        {
          date: '2025-03-01',
          value: 72.33,
        },
        {
          date: '2025-04-01',
          value: 77.26,
        },
        {
          date: '2025-05-01',
          value: 106.47,
        },
        {
          date: '2025-06-01',
          value: 275.43,
        },
        {
          date: '2025-07-01',
          value: 290.33,
        },
        {
          date: '2025-08-01',
          value: 239.74,
        },
        {
          date: '2025-09-01',
          value: 268.51,
        },
        {
          date: '2025-10-01',
          value: 546.64,
        },
        {
          date: '2025-11-01',
          value: 493.53,
        },
        {
          date: '2025-12-01',
          value: 512.67,
        },
      ],
    },
    UNIQUE_VIDEOS_LOADED: {
      datasetLabel: PerformanceMetricLabels.UNIQUE_VIDEOS_LOADED,
      dataset: [
        {
          date: '2025-01-01',
          value: 1.58,
        },
        {
          date: '2025-02-01',
          value: 5.88,
        },
        {
          date: '2025-03-01',
          value: 7.63,
        },
        {
          date: '2025-04-01',
          value: 9.63,
        },
        {
          date: '2025-05-01',
          value: 11.0,
        },
        {
          date: '2025-06-01',
          value: 15.0,
        },
        {
          date: '2025-07-01',
          value: 22.0,
        },
        {
          date: '2025-08-01',
          value: 18.54,
        },
        {
          date: '2025-09-01',
          value: 28.0,
        },
        {
          date: '2025-10-01',
          value: 29.21,
        },
        {
          date: '2025-11-01',
          value: 29.0,
        },
        {
          date: '2025-12-01',
          value: 30.11,
        },
      ],
    },
    GBP_WEBSITE_CLICKS: {
      datasetLabel: PerformanceMetricLabels.GBP_WEBSITE_CLICKS,
      dataset: [
        {
          date: '2025-01-01',
          value: 145.61,
        },
        {
          date: '2025-02-01',
          value: 227.58,
        },
        {
          date: '2025-03-01',
          value: 296.84,
        },
        {
          date: '2025-04-01',
          value: 213.41,
        },
        {
          date: '2025-05-01',
          value: 348.0,
        },
        {
          date: '2025-06-01',
          value: 344.71,
        },
        {
          date: '2025-07-01',
          value: 262.0,
        },
        {
          date: '2025-08-01',
          value: 554.31,
        },
        {
          date: '2025-09-01',
          value: 477.0,
        },
        {
          date: '2025-10-01',
          value: 504.57,
        },
        {
          date: '2025-11-01',
          value: 414.57,
        },
        {
          date: '2025-12-01',
          value: 874.51,
        },
      ],
    },
    GBP_IMPRESSIONS: {
      datasetLabel: PerformanceMetricLabels.GBP_IMPRESSIONS,
      dataset: [
        {
          date: '2025-01-01',
          value: 1323.31,
        },
        {
          date: '2025-02-01',
          value: 2115.05,
        },
        {
          date: '2025-03-01',
          value: 2542.47,
        },
        {
          date: '2025-04-01',
          value: 2437.33,
        },
        {
          date: '2025-05-01',
          value: 2323.07,
        },
        {
          date: '2025-06-01',
          value: 3355.64,
        },
        {
          date: '2025-07-01',
          value: 2320.78,
        },
        {
          date: '2025-08-01',
          value: 4648.86,
        },
        {
          date: '2025-09-01',
          value: 3797.64,
        },
        {
          date: '2025-10-01',
          value: 6995.4,
        },
        {
          date: '2025-11-01',
          value: 5483.97,
        },
        {
          date: '2025-12-01',
          value: 6761.63,
        },
      ],
    },
    PAGE_LOADS: {
      datasetLabel: PerformanceMetricLabels.PAGE_LOADS,
      dataset: [
        {
          date: '2025-01-01',
          value: 0,
        },
        {
          date: '2025-02-01',
          value: 0,
        },
        {
          date: '2025-03-01',
          value: 0,
        },
        {
          date: '2025-04-01',
          value: 0,
        },
        {
          date: '2025-05-01',
          value: 0,
        },
        {
          date: '2025-06-01',
          value: 0,
        },
        {
          date: '2025-07-01',
          value: 0,
        },
        {
          date: '2025-08-01',
          value: 0,
        },
        {
          date: '2025-09-01',
          value: 0,
        },
        {
          date: '2025-10-01',
          value: 0,
        },
        {
          date: '2025-11-01',
          value: 0,
        },
        {
          date: '2025-12-01',
          value: 0,
        },
      ],
    },
  },
};

interface ITimeSeries {
  date: string;
  value: number;
}

type PerformanceMetricKeys = keyof typeof PerformanceMetricLabels;

interface IPerformanceMetricData {
  datasetLabel: PerformanceMetricLabels;
  dataset: ITimeSeries[];
}

interface IPerformanceMetricObject {
  isLoading: boolean;
  data: Record<PerformanceMetricKeys, IPerformanceMetricData>;
}

export const shiftDatasetsToStartFromToday = (
  input: IPerformanceMetricObject,
  startDay: Date,
): IPerformanceMetricObject => {
  const today = startDay;
  const startingDate = new Date(today.getFullYear(), today.getMonth(), 1); // First day of current month

  const shiftedData: Record<PerformanceMetricKeys, IPerformanceMetricData> = {} as any;

  for (const key in input.data) {
    const metricKey = key as PerformanceMetricKeys;
    const originalDataset = input.data[metricKey].dataset;
    const label = input.data[metricKey].datasetLabel;

    const shiftedDataset: ITimeSeries[] = originalDataset.map((entry, index) => {
      const date = new Date(startingDate);
      date.setMonth(date.getMonth() + index);
      return {
        date: date.toISOString().split('T')[0],
        value: entry.value,
      };
    });

    shiftedData[metricKey] = {
      datasetLabel: label,
      dataset: shiftedDataset,
    };
  }

  return {
    isLoading: input.isLoading,
    data: shiftedData,
  };
};
