import { QRCodeIcon } from '../../../common/assets/newDesign/QRCodeIcon';
import { PendingTaskCard } from './PendingTaskCard';
import { makeStyles } from '@mui/styles';
import PerspectiveIcon from '../../../common/assets/newDesign/WebAppIcons/PerspectiveIcon';
import { RocketIcon } from '../../../common/assets/newDesign/WebAppIcons/rocketIcon';
import { useTypedSelector } from '../../../store';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '10px',
    width: '100%',
  },
  itemsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
    width: '100%',
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // gap: '10px',
    width: '100%',
  },
  googleProfileIcon: {
    width: '50px',
    height: '50px',
    objectFit: 'contain',
  },
  title: {
    marginTop: '8px',
    color: 'black',
    fontSize: '28px',
    fontWeight: '700',
  },
  description: {
    color: '#101828B2',
    size: '18px',
    fontWeight: '400',
    opacity: '0.7',
  },
});

const GBPIcon = () => {
  const classes = useStyles();
  return <img src="/GoogleProfile.png" className={classes.googleProfileIcon} alt="google icon" />;
};

enum pendingTasksStrings {
  title = 'Supercharge your value from SocialV today!',
  descriptions = 'Complete each of the tasks below to see your actual results.',
}
export const PendingTasks = () => {
  const classes = useStyles();
  const { connectedAccount } = useTypedSelector((state) => state.gpbSlice);
  const { items: videos } = useTypedSelector((state) => state.videos);
  const { socialVerseAll: socialverses } = useTypedSelector((state) => state.SocialVerseSlice);
  const history = useHistory();

  const hasVideos = videos.length >= 3;
  const hasEmbeddedSocialverse =
    socialverses && socialverses.length ? !!socialverses.find((item) => item.lastSeenAt) : false;
  const isGBPConnected = !!connectedAccount;
  const taskItems = [
    {
      title: 'Google Business Profile',
      description:
        'Sync videos with Google and we’ll include your profile’s performance for a full view.',
      icon: <GBPIcon />,
      path: '/google-sync',
      completed: isGBPConnected,
      onStart: () => {
        history.push('/google-sync');
      },
    },
    {
      title: 'Capture 3 Videos',
      description:
        '3+ great customer videos per month drives your performance and increases your ROI.',
      icon: <QRCodeIcon width={50} height={50} />,
      path: '/reward-campaigns',
      completed: hasVideos,
      onStart: () => {
        history.push('/reward-campaigns');
      },
    },
    {
      title: 'Embed a SocialVerse',
      description: 'Showcase customer videos on you site and we’ll track unique users and plays!',
      icon: <PerspectiveIcon height="50px" width="50px" />,
      path: '/socialverse',
      completed: hasEmbeddedSocialverse,
      onStart: () => {
        history.push('/socialverse');
      },
    },
  ];

  const renderTasks = () => {
    return taskItems.map((item, index) => {
      return (
        <PendingTaskCard
          title={item.title}
          description={item.description}
          icon={item.icon}
          completed={item.completed}
          key={index}
          onStart={item.onStart}
        />
      );
    });
  };
  return (
    <>
      <div className={classes.container}>
        <div className={classes.textWrapper}>
          <RocketIcon width={30} height={30} />
          <p className={classes.title}>{pendingTasksStrings.title}</p>
          <p className={classes.description}>{pendingTasksStrings.descriptions}</p>
        </div>
        <div className={classes.itemsContainer}>{renderTasks()}</div>
      </div>
    </>
  );
};
