import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FC } from 'react';

const useStyles = makeStyles({
  title: {
    fontSize: '24px',
    fontWeight: '600',
    textAlign: 'center',
    color: '#000000',
  },
});

interface TopChartSectionProps {
  title: string;
}

export const TopChartSection: FC<TopChartSectionProps> = ({ title }) => {
  const classes = useStyles();
  return (
    <>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <p className={classes.title}>{title}</p>
        </Grid>
        <Grid item xs={4}></Grid>
      </Grid>
    </>
  );
};
