import React, { useState } from 'react';
import { Box, FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { setPlottedLines } from '../../../store/slices/PerformanceSlice';
import { PerformanceMetrics } from '../../../api/models/performance';

type MetricsGroup = {
  label: string;
  metrics: PerformanceMetrics[];
};

type Props = {
  availableOptions: Record<PerformanceMetrics, boolean>;
  //   onSelect: (plottedLines: Record<PerformanceMetrics, boolean>) => void;
};

const metricsGroups: MetricsGroup[] = [
  {
    label: 'Visitors & Plays',
    metrics: [PerformanceMetrics.UNIQUE_VISITORS, PerformanceMetrics.VIDEOS_PLAYED],
  },
  {
    label: 'Plays & Converts',
    metrics: [PerformanceMetrics.VIDEOS_PLAYED, PerformanceMetrics.CONVERSIONS],
  },
  {
    label: 'Loads & Visitors',
    metrics: [PerformanceMetrics.PAGE_LOADS, PerformanceMetrics.UNIQUE_VISITORS],
  },
  {
    label: 'Clicks & Loads',
    metrics: [PerformanceMetrics.GBP_WEBSITE_CLICKS, PerformanceMetrics.PAGE_LOADS],
  },
  {
    label: 'Impressions & Clicks',
    metrics: [PerformanceMetrics.GBP_IMPRESSIONS, PerformanceMetrics.GBP_WEBSITE_CLICKS],
  },
];

export const MetricsGroupSelector: React.FC<Props> = ({ availableOptions }) => {
  const { PlottedLines } = useTypedSelector((state) => state.Performance);
  const matchedGroup = React.useMemo(() => {
    return metricsGroups.find(({ metrics }) => metrics.every((metric) => PlottedLines[metric]));
  }, [PlottedLines]);

  const [selectedGroup, setSelectedGroup] = useState<string>(matchedGroup?.label || '');
  const dispatch = useAppDispatch();

  const handleChange = (event: SelectChangeEvent) => {
    const label = event.target.value;
    setSelectedGroup(label);

    const group = metricsGroups.find((g) => g.label === label);
    if (!group) return;

    const newPlottedLines: Record<PerformanceMetrics, boolean> = {
      ...Object.keys(availableOptions).reduce(
        (acc, key) => ({
          ...acc,
          [key as PerformanceMetrics]: group.metrics.includes(key as PerformanceMetrics),
        }),
        {} as Record<PerformanceMetrics, boolean>,
      ),
      SV_VALUE: true,
      LIVE_VIDEOS: true,
    };
    dispatch(setPlottedLines(newPlottedLines));
    // onSelect(newPlottedLines);
  };

  return (
    <Box
      sx={{
        minWidth: 200,
        height: '170px',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
      }}
    >
      <FormControl fullWidth>
        {/* <InputLabel id="metric-group-select-label">Select Metric Group</InputLabel> */}
        <Select
          //   labelId="metric-group-select-label"
          value={selectedGroup}
          //   label="Select Metric Group"
          onChange={handleChange}
        >
          {metricsGroups.map(({ label, metrics }) => {
            const isEnabled = metrics.every((m) => availableOptions[m]);
            return (
              <MenuItem key={label} value={label} disabled={!isEnabled}>
                {label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};
