import { FC, useEffect } from 'react';
import { TopBar } from '../../common/components/topBar/TopBar';
import { ChartSection } from './ChartSection/ChartSection';
// import { BodyContentContainer } from '../../common/styles/commonStyles.style';
import { DashboardContainer } from './Performance.style';

import { useStyles } from './Performance.helper';
import { NoResultsContent } from './ChartSection/NoResultsContent';
import { StatsOverview } from './Components/StatsOverview';
import { formatName, getTodayAndSameDateLastYear } from '../../services/utilities';
import { useAppDispatch, useTypedSelector } from '../../store';
import {
  getPerformanceDataTimeSeries,
  getPerformanceDataTotals,
} from '../../store/slices/PerformanceSlice';
import { PerformanceTotalMetricLabels, PerformanceMetrics } from '../../api/models/performance';
import { getAllSocialVerse } from '../../store/slices/socialVerseSlice';
import { getConnectedAccount } from '../../store/slices/gbpSlice';
import { getVideos } from '../../store/slices/videosSlice';
import { baseData, shiftDatasetsToStartFromToday } from './ChartSection/ProjectedData';

export const PerformanceData: FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { TimeSeries, Totals, PlottedLines } = useTypedSelector((state) => state.Performance);
  const { firstName, lastName } = useTypedSelector((state) => state.me);
  const { id: accountId, createdAt } = useTypedSelector((state) => state.venue.venue);
  const { connectedAccount, isLoading: isGBPLoading } = useTypedSelector((state) => state.gpbSlice);
  const { items: videos, isLoading: isVideosLoading } = useTypedSelector((state) => state.videos);
  const { socialVerseAll: socialverses, isLoading: isSocialversesLoading } = useTypedSelector(
    (state) => state.SocialVerseSlice,
  );
  const fetchTotalsAndMetrics = () => {
    const metrics = Object.values(PerformanceMetrics).join(',');
    const { today, lastYear } = getTodayAndSameDateLastYear();
    dispatch(getPerformanceDataTotals({ from: lastYear, to: today, accountId, metrics }));
    dispatch(getPerformanceDataTimeSeries({ from: lastYear, to: today, accountId, metrics }));
  };

  useEffect(() => {
    if (accountId) {
      dispatch(getAllSocialVerse({ accountId, withVideos: false }));
      dispatch(getConnectedAccount({ accountId }));
      dispatch(getVideos({ accountId, pageable: { page: 0, size: 20 } }));
      fetchTotalsAndMetrics();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  const isLoading = isVideosLoading || isGBPLoading || isSocialversesLoading;
  const hasVideos = videos.length >= 3;
  const hasEmbeddedSocialverse =
    socialverses && socialverses.length ? !!socialverses.find((item) => item.lastSeenAt) : false;
  const isGBPConnected = connectedAccount;
  const showPendingTasks = !hasVideos || !hasEmbeddedSocialverse || !isGBPConnected;
  const displayName = formatName(firstName, lastName);
  const totalsDisplayed = {
    SV_VALUE: true,
    CONVERSIONS: true,
    UNIQUE_VISITORS: true,
    GBP_VIDEOS: true,
    LIVE_VIDEOS: true,
    VIDEOS: true,
    VIDEOS_PLAYED: false,
    UNIQUE_VIDEOS_LOADED: false,
    PAGE_LOADS: false,
    GBP_WEBSITE_CLICKS: false,
    GBP_IMPRESSIONS: false,
  };

  const lines = Object.keys(PlottedLines).filter(
    (item) => PlottedLines[item as PerformanceMetrics] === true,
  );
  const startDate = createdAt ? new Date(createdAt) : new Date();
  const projectedResults = shiftDatasetsToStartFromToday(baseData, startDate);
  const projectedChartData = lines.map((item) => projectedResults.data[item as PerformanceMetrics]);
  const chartData = lines.map((item) => TimeSeries.data[item as PerformanceMetrics]);

  const totalToDisplay = Object.keys(totalsDisplayed).filter(
    (item) => totalsDisplayed[item as PerformanceMetrics] === true,
  );
  const totalStats = totalToDisplay.map((item) => {
    return {
      label: PerformanceTotalMetricLabels[item as PerformanceMetrics],
      value: Totals.data[item as PerformanceMetrics],
    };
  });

  return (
    <DashboardContainer>
      <TopBar sectionTitle={`Welcome back, ${displayName}`} />
      <StatsOverview totalStats={totalStats} />

      {/* <div style={{ marginLeft: '20px', marginRight: '20px', marginTop: '30px' }}>
        <AnalyticsSection
          isHeroes={isHeroes}
          onClick={() => {}}
          activeAnalyticsBlock={{
            mainName: '',
            main: null,
            sub: null,
          }}
        />
      </div> */}

      <div className={classes.chartWrapper}>
        {/* <TopbarGraph
          activeAnalyticsBlock={activeAnalyticsBlock}
          dropdownComponent={
            <SharesSubAnalyticsNavigation setPlatform={setPlatform} platform={platform} />
          }
        /> */}

        <ChartSection
          // kpis={kpis}
          chartData={chartData}
          projectedChartData={projectedChartData}
          isChart={true}
          isLoading={TimeSeries.isLoading || isLoading}
          onClose={() => {}}
          onClickChartAnalyticsBlock={() => {}}
          showPendingTasks={showPendingTasks}
          // activeAnalyticsBlock={activeAnalyticsBlock}
        >
          {true ? null : <NoResultsContent />}
          {/* {isTopgolfVenue ? renderTopGolfConversionRate : renderTopbarGraphic()} */}
        </ChartSection>
      </div>
    </DashboardContainer>
  );
};
