import { Grid } from '@mui/material';
import { CircleValueDisplay } from '../../../common/components/CircleValueDisplay/CircleValueDisplay';
import { makeStyles } from '@mui/styles';
import { FC } from 'react';
import { MetricsGroupSelector } from './MetricsGroupSelector';
import { useTypedSelector } from '../../../store';
//CircleValueDisplay

const useStyles = makeStyles(() => ({
  container: {
    border: '1px solid #D9D9D9 ',
    borderRadius: '0px 0px 10px 10px',
    padding: '14px 10px',
  },
}));

const sectionColors = ['#34A853', '#2D70FF', '#EA4335', '#FFB515'];
const projectionColors = ['#9AC88E', '#96C5FF', '#EA9191', '#EEDFA8'];
//#D9D9D9
interface BottomChartSectionProps {
  stats: { value: string; label: string }[];
  isProjected: boolean;
}
export const BottomChartSection: FC<BottomChartSectionProps> = ({ stats, isProjected }) => {
  const classes = useStyles();
  const { availableOptions } = useTypedSelector((state) => state.Performance);
  const colors = isProjected ? projectionColors : sectionColors;
  const statsWithColors = stats.map((item, index) => {
    return { ...item, color: colors[index] };
  });
  const renderMonthValues = () => {
    return statsWithColors.map((item, index) => {
      return (
        <Grid item xs={3} key={index}>
          <CircleValueDisplay color={item.color} label={item.label} value={item.value} />
        </Grid>
      );
    });
  };
  return (
    <Grid container alignItems="center" justifyContent="center" className={classes.container}>
      <Grid item xs={3}>
        <MetricsGroupSelector availableOptions={availableOptions} />
      </Grid>
      <Grid item xs={9}>
        <Grid container alignItems="center" justifyContent="center">
          {renderMonthValues()}
        </Grid>
      </Grid>
    </Grid>
  );
};
