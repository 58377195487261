import { FC } from 'react';
import { Box, List } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useTypedSelector } from '../../../../store';
import { segmentTrackCall } from '../../../../utils/segment';
import { segmentActions, UserRoles } from '../../../constants/constants';
import { WebsiteRoutes } from '../../../constants/routes';
import { IMenuItem, menuItems, useStylesList } from './Menu.helper';
import { MenuItem } from './menuItem/MenuItem';
import { SidebarStrings } from '../../../localization/en';
import { useClientType } from '../../../../services/hooks/useClientType';
import { useQuickStartLaunchPad } from '../../../../pages/QuickStart/QuickStartLaunchPadModal/useQuickStartLaunchPad';
// import { useSidebarFTE } from '../../../../hooks/useSidebarFTE';

interface MenuProps {
  collapsed: boolean;
  isSuperAdmin: boolean;
  showVenuMenu: boolean;
  itemClickHandler?: () => void;
}

export const Menu: FC<MenuProps> = ({
  collapsed,
  isSuperAdmin,
  showVenuMenu,
  itemClickHandler,
}) => {
  const { pathname } = useLocation();
  const classes = useStylesList();

  // const { unvisitedRoutes } = useSidebarFTE();

  const { venue } = useTypedSelector((state) => state.venue);

  const { role } = useTypedSelector((state) => state.me);
  const clickHandler = (name: string) => {
    segmentTrackCall(`${segmentActions.SideBar}_${name}`, venue.id);
    if (typeof itemClickHandler !== 'undefined') {
      itemClickHandler();
    }
  };

  const { isTopGolfClient, isHealthCare } = useClientType();

  const { isStarted: isQuickstartFlow } = useQuickStartLaunchPad();

  const renderItems = (items: IMenuItem[]) =>
    items
      .filter((item) =>
        item.route === WebsiteRoutes.Integrations ? role !== UserRoles.venue_manager : true,
      )
      .filter((item) => (item.isSuperAdmin ? isSuperAdmin : showVenuMenu))
      .filter((item) => isTopGolfClient || item.route !== WebsiteRoutes.DashboardHeroes)
      .filter((item) => (isTopGolfClient ? item.route !== WebsiteRoutes.Performance : true))
      .map((item) => ({
        ...item,
        subMenuItems: item.subMenuItems?.filter((subMenuItem) =>
          subMenuItem.route === WebsiteRoutes.SettingsManagers
            ? role === UserRoles.admin_role
            : true,
        ),
      }))
      // Below map function does:
      // Remove signup config menu item for topgolf clientType,
      // Not default side bar menu item to web app signup config
      .map((item) => {
        let temp = item.subMenuItems || [];
        if (item.text === SidebarStrings.Configurations && isTopGolfClient && item.subMenuItems) {
          temp.splice(1, 1, {
            ...item.subMenuItems[1],
            route: WebsiteRoutes.BrandingStyle,
          });
        }
        return item.text === SidebarStrings.Configurations && isTopGolfClient
          ? { ...item, subMenuItems: temp }
          : item;
      })
      .filter((item) => (isTopGolfClient ? !item.isNotTopGolfItem : !item.isTopGolfItem))
      .map((item) => (
        <MenuItem
          item={item}
          key={item.route + isQuickstartFlow}
          itemClickHandler={() => clickHandler(item.text.toLowerCase())}
          blur={false}
          FTEIsFinished={!isQuickstartFlow}
          showPulseNotification={false}
        />
      ));

  return (
    <Box display="flex" flexDirection="column" flexGrow={1}>
      <List className={classes.root} component="nav">
        {!pathname.includes('admin') && <div>{renderItems(menuItems(isHealthCare))}</div>}
        {/* removed customization section SV-341 Update the dashboard left navigation column */}
        {/* <div className={classes.sectionDivider}>{renderItems(managerItems)}</div> */}
      </List>
    </Box>
  );
};
