export enum PerformanceMetrics {
  VIDEOS = 'VIDEOS',
  GBP_VIDEOS = 'GBP_VIDEOS',
  UNIQUE_VISITORS = 'UNIQUE_VISITORS',
  LIVE_VIDEOS = 'LIVE_VIDEOS',
  VIDEOS_PLAYED = 'VIDEOS_PLAYED',
  UNIQUE_VIDEOS_LOADED = 'UNIQUE_VIDEOS_LOADED',
  PAGE_LOADS = 'PAGE_LOADS',
  GBP_WEBSITE_CLICKS = 'GBP_WEBSITE_CLICKS',
  GBP_IMPRESSIONS = 'GBP_IMPRESSIONS',
  SV_VALUE = 'SV_VALUE',
  CONVERSIONS = 'CONVERSIONS',
}
export enum PerformanceMetricLabels {
  VIDEOS = 'Videos',
  GBP_VIDEOS = 'GBP Videos',
  UNIQUE_VISITORS = 'Unique Vistors',
  LIVE_VIDEOS = 'Live Videos',
  VIDEOS_PLAYED = 'Video Plays',
  UNIQUE_VIDEOS_LOADED = 'Unique Video Loaded',
  PAGE_LOADS = 'Page Loads',
  GBP_WEBSITE_CLICKS = 'GBP Website Clicks',
  GBP_IMPRESSIONS = 'GBP Impressions',
  SV_VALUE = 'Value',
  CONVERSIONS = 'Conversion',
}
export enum PerformanceTotalMetricLabels {
  VIDEOS = 'Total Videos',
  GBP_VIDEOS = 'Total GBP Videos',
  UNIQUE_VISITORS = 'Total Unique Vistors',
  LIVE_VIDEOS = 'Total Live Videos',
  VIDEOS_PLAYED = 'Total Video Plays',
  UNIQUE_VIDEOS_LOADED = 'Total Unique Video Loaded',
  PAGE_LOADS = 'Total Page Loads',
  GBP_WEBSITE_CLICKS = 'Total GBP Website Clicks',
  GBP_IMPRESSIONS = 'Total GBP Impressions',
  SV_VALUE = 'Total Value',
  CONVERSIONS = 'Total Conversions',
}

export interface GetPerformanceTotalResponse {
  fromDate: string;
  toDate: string;
  values: [
    {
      metric: PerformanceMetrics;
      value: number;
    },
  ];
}

export interface ITimeSeries {
  date: string;
  value: 0;
}

export interface GetPerformanceTimeSeries {
  fromDate: string;
  toDate: string;
  metricTimeSeries: [
    {
      metric: PerformanceMetrics;
      timeSeries: ITimeSeries[];
    },
  ];
}

export interface IPlottedLines<T> {
  VIDEOS: T;
  GBP_VIDEOS: T;
  LIVE_VIDEOS: T;
  UNIQUE_VISITORS: T;
  VIDEOS_PLAYED: T;
  UNIQUE_VIDEOS_LOADED: T;
  PAGE_LOADS: T;
  GBP_WEBSITE_CLICKS: T;
  GBP_IMPRESSIONS: T;
  SV_VALUE: T;
  CONVERSIONS: T;
}

export interface IPerformanceinitialState {
  availableOptions: IPlottedLines<boolean>;
  PlottedLines: IPlottedLines<boolean>;
  Totals: {
    isLoading: boolean;
    data: IPlottedLines<number>;
  };
  TimeSeries: {
    isLoading: boolean;
    data: {
      VIDEOS: {
        datasetLabel: PerformanceMetricLabels;
        dataset: ITimeSeries[];
      };
      GBP_VIDEOS: {
        datasetLabel: PerformanceMetricLabels;
        dataset: ITimeSeries[];
      };
      LIVE_VIDEOS: {
        datasetLabel: PerformanceMetricLabels;
        dataset: ITimeSeries[];
      };
      UNIQUE_VISITORS: {
        datasetLabel: PerformanceMetricLabels;
        dataset: ITimeSeries[];
      };
      VIDEOS_PLAYED: {
        datasetLabel: PerformanceMetricLabels;
        dataset: ITimeSeries[];
      };
      UNIQUE_VIDEOS_LOADED: {
        datasetLabel: PerformanceMetricLabels;
        dataset: ITimeSeries[];
      };
      PAGE_LOADS: {
        datasetLabel: PerformanceMetricLabels;
        dataset: ITimeSeries[];
      };
      GBP_WEBSITE_CLICKS: {
        datasetLabel: PerformanceMetricLabels;
        dataset: ITimeSeries[];
      };
      GBP_IMPRESSIONS: {
        datasetLabel: PerformanceMetricLabels;
        dataset: ITimeSeries[];
      };
      SV_VALUE: {
        datasetLabel: PerformanceMetricLabels;
        dataset: ITimeSeries[];
      };
      CONVERSIONS: {
        datasetLabel: PerformanceMetricLabels;
        dataset: ITimeSeries[];
      };
      //GBP_IMPRESSIONS
    };
  };
}
