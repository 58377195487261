import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  PerformanceMetricLabels,
  GetPerformanceTotalResponse,
  GetPerformanceTimeSeries,
  IPerformanceinitialState,
  IPlottedLines,
} from '../../api/models/performance';
import { httpClient } from '../../services/httpClient/httpClient';
import { PerformanceEndpoints } from '../../api/endpoints';

//VIDEOS, GBP_VIDEOS, UNIQUE_VISITORS, LIVE_VIDEOS, VIDEOS_PLAYED, UNIQUE_VIDEOS_LOADED, PAGE_LOADS, GBP_WEBSITE_CLICKS, GBP_IMPRESSIONS, SV_VALUE, CONVERSIONS
const initialState: IPerformanceinitialState = {
  availableOptions: {
    SV_VALUE: true,
    LIVE_VIDEOS: true,
    VIDEOS: true,
    GBP_VIDEOS: true,
    UNIQUE_VISITORS: true,
    VIDEOS_PLAYED: true,
    UNIQUE_VIDEOS_LOADED: true,
    PAGE_LOADS: true,
    GBP_WEBSITE_CLICKS: true,
    GBP_IMPRESSIONS: true,
    CONVERSIONS: true,
  },
  PlottedLines: {
    SV_VALUE: true,
    LIVE_VIDEOS: true,
    VIDEOS: false,
    GBP_VIDEOS: false,
    UNIQUE_VISITORS: true,
    VIDEOS_PLAYED: true,
    UNIQUE_VIDEOS_LOADED: false,
    PAGE_LOADS: false,
    GBP_WEBSITE_CLICKS: false,
    GBP_IMPRESSIONS: false,
    CONVERSIONS: false,
  },
  Totals: {
    isLoading: false,
    data: {
      VIDEOS: 0,
      GBP_VIDEOS: 0,
      LIVE_VIDEOS: 0,
      UNIQUE_VISITORS: 0,
      VIDEOS_PLAYED: 0,
      UNIQUE_VIDEOS_LOADED: 0,
      PAGE_LOADS: 0,
      GBP_WEBSITE_CLICKS: 0,
      GBP_IMPRESSIONS: 0,
      SV_VALUE: 0,
      CONVERSIONS: 0,
    },
  },
  TimeSeries: {
    isLoading: false,
    data: {
      VIDEOS: {
        datasetLabel: PerformanceMetricLabels.VIDEOS,
        dataset: [],
      },
      GBP_VIDEOS: {
        datasetLabel: PerformanceMetricLabels.GBP_VIDEOS,
        dataset: [],
      },
      LIVE_VIDEOS: {
        datasetLabel: PerformanceMetricLabels.LIVE_VIDEOS,
        dataset: [],
      },
      UNIQUE_VISITORS: {
        datasetLabel: PerformanceMetricLabels.UNIQUE_VISITORS,
        dataset: [],
      },
      VIDEOS_PLAYED: {
        datasetLabel: PerformanceMetricLabels.VIDEOS_PLAYED,
        dataset: [],
      },
      UNIQUE_VIDEOS_LOADED: {
        datasetLabel: PerformanceMetricLabels.UNIQUE_VIDEOS_LOADED,
        dataset: [],
      },
      PAGE_LOADS: {
        datasetLabel: PerformanceMetricLabels.PAGE_LOADS,
        dataset: [],
      },
      GBP_WEBSITE_CLICKS: {
        datasetLabel: PerformanceMetricLabels.GBP_WEBSITE_CLICKS,
        dataset: [],
      },
      GBP_IMPRESSIONS: {
        datasetLabel: PerformanceMetricLabels.GBP_IMPRESSIONS,
        dataset: [],
      },
      SV_VALUE: {
        datasetLabel: PerformanceMetricLabels.SV_VALUE,
        dataset: [],
      },
      CONVERSIONS: {
        datasetLabel: PerformanceMetricLabels.CONVERSIONS,
        dataset: [],
      },
      //GBP_IMPRESSIONS
    },
  },
};

export const getPerformanceDataTotals = createAsyncThunk(
  'performanceData/getPerformanceDataTotals',
  async (
    options: { accountId: string; from: string; to: string; metrics: string },
    { rejectWithValue },
  ) => {
    try {
      return await httpClient.get<
        { accountId: string; from: string; to: string; metrics: string },
        GetPerformanceTotalResponse
      >({
        url: PerformanceEndpoints.getPerformanceTotals,
        requiresToken: true,
        params: options,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);
export const getPerformanceDataTimeSeries = createAsyncThunk(
  'performanceData/getPerformanceDataTimeSeries',
  async (
    options: { accountId: string; from: string; to: string; metrics: string },
    { rejectWithValue },
  ) => {
    try {
      return await httpClient.get<
        { accountId: string; from: string; to: string; metrics: string },
        GetPerformanceTimeSeries
      >({
        url: PerformanceEndpoints.getPerformanceTimeSeries,
        requiresToken: true,
        params: options,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

const performanceSlice = createSlice({
  name: 'performanceData',
  initialState,
  reducers: {
    // triggerSidebar(state) {
    //   state.sidebarOpen = !state.sidebarOpen;
    // },
    // setIsCummulativeGraph(state, action: PayloadAction<boolean>) {
    //   state.isCummulativeGraph = action.payload;
    // },
    setPlottedLines(state, action: PayloadAction<IPlottedLines<boolean>>) {
      state.PlottedLines = action.payload;
    },
    setAvailableOptions(state, action: PayloadAction<IPlottedLines<boolean>>) {
      state.availableOptions = action.payload;
    },
    reset: () => ({ ...initialState }),
  },
  extraReducers: (reducersBuilder) => {
    reducersBuilder.addCase(getPerformanceDataTotals.rejected, (state) => {
      state.Totals.isLoading = false;
    });
    reducersBuilder.addCase(getPerformanceDataTotals.pending, (state) => {
      state.Totals.isLoading = true;
    });
    reducersBuilder.addCase(getPerformanceDataTotals.fulfilled, (state, { payload }) => {
      state.Totals.isLoading = false;
      const { values } = payload;
      if (values.length) {
        values.forEach((item) => {
          state.Totals.data[item.metric] = item.value;
        });
      }
      // state.Totals
    });
    //getAccountsBySearchFilter
    reducersBuilder.addCase(getPerformanceDataTimeSeries.rejected, (state) => {
      state.TimeSeries.isLoading = false;
    });
    reducersBuilder.addCase(getPerformanceDataTimeSeries.pending, (state) => {
      state.TimeSeries.isLoading = true;
    });
    reducersBuilder.addCase(getPerformanceDataTimeSeries.fulfilled, (state, { payload }) => {
      state.TimeSeries.isLoading = false;
      const { metricTimeSeries } = payload;
      metricTimeSeries.forEach((item) => {
        if (item.metric) {
          state.TimeSeries.data[item.metric].dataset = item.timeSeries;
        }
      });
    });
  },
});

export const { reset, setPlottedLines, setAvailableOptions } = performanceSlice.actions;
export default performanceSlice.reducer;
