import styled from 'styled-components';

export const ChartComponentStyled = styled.div`
  position: relative;
`;

export const ChartContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  padding: 0 44px 20px 0;
  background: white;
  border-radius: 16px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    height: 330px;
    padding: 0 18px 16px;
    border-radius: 0;
  }
`;

export const Chart = styled.div`
  position: relative;
  width: 100%;
  height: 340px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: white;

  @media (max-width: 768px) {
    height: 204px;
  }
`;

const labelText = `
  font-size: 10px;
  letter-spacing: 0.4px;
  color: var(--secondaryColor);
`;

export const MonthLabel = styled.span`
  background: #e0dfff;
  border-radius: 6px;
  font-weight: bold;
  padding: 0.25em 0.5em;
`;

export const ChartWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  ${labelText}
`;

export const TimezoneText = styled.div`
  margin-top: 5px;
  ${labelText}
`;

export const ChartLeftLabel = styled.div`
  margin-right: -10px;
  transform: rotate(-90deg);
  white-space: nowrap;
  width: 55px;
`;
export const ChartRightLabel = styled.div`
  width: 30px;
  align-self: center;
  margin-top: 150px;
  white-space: nowrap;
  transform: rotate(-90deg);
`;

