import React from 'react';
import { BrushCircle } from '../../assets/BrushCircle';
import { makeStyles } from '@mui/styles';
interface CircleValueDisplayProps {
  value: string;
  label: string;
  color: string;
}

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  circleWrapper: {
    position: 'relative',
    width: 150,
    height: 150,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  valueText: {
    position: 'absolute',
    inset: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.125rem', // equivalent to Tailwind's text-lg
    fontWeight: 700, // equivalent to Tailwind's font-bold
    color: '#000',
  },
  label: {
    marginTop: 8,
    fontSize: '0.875rem',
    color: '#000',
    display: 'flex',
    alignItems: 'center',
    gap: 4,
  },
}));

export const CircleValueDisplay: React.FC<CircleValueDisplayProps> = ({ value, label, color }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.circleWrapper}>
        <BrushCircle
          //   className="w-full h-full"
          color={color}
        />
        <div className={classes.valueText}>{value}</div>
      </div>
      <div className={classes.label}>
        {label}
        <span title="More info">ℹ️</span>
      </div>
    </div>
  );
};
