import { Box } from '@mui/material';
import { FC } from 'react';

import { SuperAdminAnalyticsBlock } from '../admin/performance/analyticsBlock/SuperAdminAnalyticsBlock';
import { VenuesTable } from '../admin/performance/tableSection/VenuesTable';
import { useSuperAdminAccountsPageStyles } from './SuperAdminAccountsPage.style';
import { isFirefox, isSafari } from 'react-device-detect';
import { AccountsAdvancedSearch } from '../../common/components/AccountsAdvancedSearch/AccountsAdvancedSearch';
import AccountsSearchTags from '../../common/components/AccountsSearchTags/AccountsSearchTags';
import { useAppDispatch, useTypedSelector } from '../../store';
import {
  getAccountsBySearchFilter,
  getAccountsBySearchFilterAPICall,
} from '../../store/slices/organizationVenueSlice';
import { TableToolbar } from '../content/tableSection/TableToolbar/TableToolbar';
import { resetActiveAccountFilterAttributes } from '../../store/slices/searchFilterSlice';
import { useJsonToCSV } from '../../hooks/useJsonToCSV';
import { accountHeaders } from '../filters/CreatedFilter.helper';
import formatDate from 'date-fns/format';
import { NavAlt } from '../../common/layout/sideBar/nav-alt/nav-alt';
import { UserRoles } from '../../common/constants/constants';

interface IsuperAdminAccountsPageProps {
  //
}
const TOP_BAR = 96;
const STATISTIC_BLOCK = 188;
const SEARCH_BAR = 64;
const PAGINATION = 52;
const PADDING_BOTTOM = 50;
const SCROLL = 10;

export const SuperAdminAccountsPage: FC<IsuperAdminAccountsPageProps> = () => {
  const styles = useSuperAdminAccountsPageStyles();
  const dispatch = useAppDispatch();
  const { jsonToCSV } = useJsonToCSV();
  const { items, totalItems, sort, isArchivedAccount } = useTypedSelector(
    (state) => state.OrganizationVenueSlice,
  );
  const { role } = useTypedSelector((state) => state.me);
  const { distributors, activeDistributor } = useTypedSelector((state) => state.distributors);
  const { affiliates, activeAffiliate } = useTypedSelector((state) => state.affiliates);
  const { groupId, size, page } = useTypedSelector((state) => state.OrganizationVenueSlice);
  const includeTestGroups = (!!activeAffiliate && !!activeDistributor) || isArchivedAccount;

  const { activeAccountFilterAttributes, resetTags, search: filterSearch } = useTypedSelector(
    (state) => state.searchFilter,
  );
  let top = TOP_BAR + STATISTIC_BLOCK + PADDING_BOTTOM + SCROLL;
  if (isSafari) {
    top = TOP_BAR + SEARCH_BAR + STATISTIC_BLOCK + PAGINATION + PADDING_BOTTOM;
  }
  if (isFirefox) {
    top = (TOP_BAR + SEARCH_BAR + STATISTIC_BLOCK + PAGINATION + PADDING_BOTTOM) * 1.0;
  }
  const showTableToolbar = !!activeAccountFilterAttributes.length;
  const isSuperAdmin =
    ([UserRoles.admin_role] as string[]).includes(role) ||
    !!distributors.length ||
    !!affiliates.length;

  const searchParams = {
    size,
    sort: sort.toString(),
    filterSearch,
    page: page.toString(),
  };

  return (
    <Box
      className={styles.tableWrapper}
      sx={{
        '& #common-table-wrapper': {
          maxHeight: `calc(100vh - ${top}px)`,
          height: `calc(100vh - ${top}px)`,
        },
      }}
    >
      <VenuesTable>
        <>
          <Box
            sx={{
              backgroundColor: '#f5f6f8',
              pt: '10px',
              pb: '15px',
              zIndex: 10,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItem: 'center',
                justifyContent: 'space-between',
                marginTop: '-20px',
              }}
            >
              <AccountsAdvancedSearch />
              {isSuperAdmin && <NavAlt isOpen={true} />}
            </Box>

            {activeAccountFilterAttributes.length > 0 && resetTags !== null && (
              <AccountsSearchTags />
            )}
            <Box>
              {showTableToolbar && (
                <TableToolbar
                  tableEntity="Account"
                  itemsShowing={items.length}
                  itemsTotal={totalItems}
                  searchMode={true}
                  hideKeywords
                  onSearchResetClick={() => {
                    dispatch(
                      getAccountsBySearchFilter({
                        params: {
                          ...searchParams,
                          search: filterSearch,
                        },
                        filter: activeAccountFilterAttributes,
                        // withStats: true,
                        groupId: activeDistributor
                          ? activeDistributor.groupId
                          : activeAffiliate
                          ? activeAffiliate.groupId
                          : groupId,
                        includeTestGroups: includeTestGroups,
                        deactivated: isArchivedAccount,
                      }),
                    );
                  }}
                  searchExportEnabled={totalItems < 100}
                  onSearchExportClick={async () => {
                    const data = await getAccountsBySearchFilterAPICall({
                      params: {
                        page: '0',
                        size: 100,
                        sort: sort.toString(),
                        search: filterSearch,
                      },
                      filter: activeAccountFilterAttributes,
                      // withStats: true,
                      groupId,
                      includeTestGroups: includeTestGroups,
                      deactivated: isArchivedAccount,
                    });
                    jsonToCSV({
                      data: data.items ?? [],
                      headers: accountHeaders,
                      filename: `SocialV_Accounts_${formatDate(new Date(), 'MMM-dd-y')}`,
                    });
                  }}
                  onClearSearchClick={() => {
                    dispatch(resetActiveAccountFilterAttributes());
                  }}
                />
              )}
            </Box>
          </Box>
          <Box sx={{ backgroundColor: '#f5f6f8', pt: '10px', pb: '15px' }}>
            <SuperAdminAnalyticsBlock />
          </Box>
        </>
      </VenuesTable>
    </Box>
  );
};
