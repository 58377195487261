import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { httpClient } from '../../services/httpClient/httpClient';
import { VideosEndpoints, getApiUrlForId } from '../../api/endpoints';
import { GetTableDataResponse } from '../../api/models/common';
import {
  VideoApiModel,
  GetVideosRequest,
  DeleteVideoRequest,
  DeleteVideoResponse,
  GetVideosRequestOptions,
  UpdateVideoRequestOptions,
  UpdateVideoRequest,
  GetVideoByIdRequestOptions,
  GetVideosRequestWithSearchFilterOptions,
  SharesAnalyticsData,
} from '../../api/models/videos';
import { getTableSorting, tableLoadFulFilled, tableLoadPending, tableLoadRejected } from './utils';
import { defaultPagination } from '../../common/constants/constants';
import { TableDTO } from '../storeModels';
import { fillEmptyUserFields, getUnbrandedThumbnail } from '../../services/utilities';

const initialState: TableDTO<VideoApiModel> & { shouldRefetch?: boolean; loadMore?: boolean } = {
  error: false,
  isLoading: false,
  items: [],
  page: defaultPagination.page,
  size: defaultPagination.size,
  totalItems: defaultPagination.totalItems,
  totalPages: defaultPagination.totalPages,
  sort: defaultPagination.sortByLastCreated,
  lastUpdated: '',
  selectedRows: undefined,
  previousPage: defaultPagination.page,
  previousSize: defaultPagination.size,
  shouldRefetch: false,
};

export const updateVideoById = createAsyncThunk(
  'videos/updateVideo',
  async (options: UpdateVideoRequestOptions, { rejectWithValue }) => {
    try {
      return await httpClient.put<UpdateVideoRequest, VideoApiModel>({
        url: getApiUrlForId(VideosEndpoints.UpdateVideoById, options.id),
        requiresToken: true,
        payload: options.update,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const fetchVideosBySearchFilterApiCall = async (
  options: GetVideosRequestWithSearchFilterOptions,
) => {
  const data = await httpClient.post<GetVideosRequest, GetTableDataResponse<VideoApiModel>>({
    url: VideosEndpoints.GetVideosBySearchFilter,
    requiresToken: true,
    payload: {
      attributes:
        'id' in options.filter ? [...options.filter.filter.attributes] : [...options.filter],
    },
    params: {
      accountId: options.venueId,
      page: options.pageable.page?.toString(),
      size: options.pageable.size?.toString(),
      sort: options.pageable.sort?.toString(),
      search: options.search,
      withTranscript: true,
    },
  });

  data.items = getUnbrandedThumbnail(data.items).map((item) => ({
    ...item,
    userName: item.userName.trim() ? item.userName : 'Anonymous Creator',
    userDisplayName: item.userDisplayName || item.userName || 'Anonymous Creator',
  }));

  return data;
};

export const getVideosBySearchFilter = createAsyncThunk(
  'videos/getVideosBySearchFilter',
  async (options: GetVideosRequestWithSearchFilterOptions, { rejectWithValue, getState }) => {
    try {
      return fetchVideosBySearchFilterApiCall(options);
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getVideoById = createAsyncThunk(
  'videos/getVideoById',
  async (options: GetVideoByIdRequestOptions, { rejectWithValue }) => {
    try {
      return await httpClient.get<{}, VideoApiModel>({
        url: getApiUrlForId(VideosEndpoints.GetVideoById, options.id),
        params: {
          withTranscript: true,
        },
        requiresToken: true,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);
export const getVideoByIdForSocialVerse = createAsyncThunk(
  'videos/getVideoByIdForSocialVerse',
  async (options: GetVideoByIdRequestOptions, { rejectWithValue }) => {
    try {
      return await httpClient.get<{}, VideoApiModel>({
        url: getApiUrlForId(VideosEndpoints.GetVideoById, options.id),
        params: {
          withTranscript: true,
        },
        requiresToken: true,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getVideoSharesById = createAsyncThunk(
  'videos/getVideoSharesById',
  async (options: GetVideoByIdRequestOptions, { rejectWithValue }) => {
    try {
      const response = await httpClient.get<{}, SharesAnalyticsData>({
        url: getApiUrlForId(VideosEndpoints.GetVideosShareSummary, options.id),
        requiresToken: true,
      });
      const payload = {
        id: options.id,
        sharesAnalyticsData: response,
      };
      return payload;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getVideos = createAsyncThunk(
  'videos/getVideos',
  async (options: GetVideosRequestOptions, { rejectWithValue, getState }) => {
    try {
      const data = await httpClient.get<GetVideosRequest, GetTableDataResponse<VideoApiModel>>({
        url: VideosEndpoints.GetVideos,
        requiresToken: true,
        params: {
          accountId: options.accountId,
          userId: options.userId,
          page: options.pageable.page?.toString(),
          size: options.pageable.size?.toString(),
          sort: options.pageable.sort?.toString(),
          status: options.pageable.status?.toUpperCase(),
          search: options.search,
          deleted: options.pageable.deleted?.toString(),
          withTranscript: true,
        },
      });

      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);
export const deleteVideoById = createAsyncThunk(
  'videos/deleteVideo',
  async (options: DeleteVideoRequest, { rejectWithValue }) => {
    try {
      return await httpClient.delete<DeleteVideoRequest, DeleteVideoResponse>({
        url: getApiUrlForId(VideosEndpoints.DeleteVideoById, options.id),
        requiresToken: true,
        params: options,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

const videosSlice = createSlice({
  name: 'videos',
  initialState,
  reducers: {
    goToSelectedPage(state, action: PayloadAction<number>) {
      state.previousPage = state.page;
      state.page = action.payload;
    },
    removeWidgetTagsFromStories(state, action: PayloadAction<{ tag: string }>) {
      state.items = state.items.map((item) => {
        if (item.widgetTag === action.payload.tag) {
          item.widgetTag = null;
        }
        return item;
      });
    },
    setVideosSorting(state, action: PayloadAction<string>) {
      const sort = getTableSorting(current(state), action.payload);

      return {
        ...initialState,
        sort,
        search: state.search,
        size: state.size,
      };
    },
    updateTable(state) {
      state.lastUpdated = new Date().toISOString();
    },
    reset: () => initialState,
    updateSize(state, action: PayloadAction<number>) {
      state.previousPage = state.page;
      state.previousSize = state.size;

      state.size = action.payload;
      state.page = 0;
    },
    resetPage(state) {
      state.page = 0;
    },
    updateSearch(state, action: PayloadAction<string>) {
      state.search = action.payload;
      state.page = initialState.page;
    },
    setRefetch(state, action: PayloadAction<boolean>) {
      state.shouldRefetch = action.payload;
    },
    setLoadMore(state, action: PayloadAction<boolean>) {
      state.loadMore = action.payload;
    },
  },
  extraReducers: (reducersBuilder) => {
    reducersBuilder.addCase(getVideosBySearchFilter.fulfilled, (state, { payload, meta }) => {
      state.lastUpdated = new Date().toISOString();
      return tableLoadFulFilled(state, payload, { loadMore: meta.arg.loadMore });
    });
    reducersBuilder.addCase(getVideosBySearchFilter.pending, (state) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(getVideos.rejected, (state) => {
      return tableLoadRejected(state);
    });
    reducersBuilder.addCase(getVideos.pending, (state) => {
      return tableLoadPending(state);
    });
    reducersBuilder.addCase(getVideos.fulfilled, (state, { payload }) => {
      let items: VideoApiModel[] = [];
      if (payload.page === 0) {
        items = payload.items;
      } else {
        items = [...state.items, ...payload.items];
      }

      items = getUnbrandedThumbnail(items).map((item) => ({
        ...item,
        userName: item.userName.trim() ? item.userName : 'Anonymous Creator',
        userDisplayName: item.userDisplayName || item.userName || 'Anonymous Creator',
      }));

      payload.items = items.map((video) => fillEmptyUserFields(video));

      state.previousPage = state.page;
      state.previousSize = state.size;
      state.page = payload.page;

      return tableLoadFulFilled(state, payload);
    });
    reducersBuilder.addCase(deleteVideoById.pending, (state, { payload }) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(deleteVideoById.fulfilled, (state, { payload }) => {
      state.items = state.items.filter((item) => item.id !== payload.id);
      state.lastUpdated = new Date().toISOString();
      state.isLoading = false;
    });
    reducersBuilder.addCase(deleteVideoById.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = true;
    });
    reducersBuilder.addCase(updateVideoById.fulfilled, (state, { payload }) => {
      state.items = state.items.map((item) => {
        if (item.id !== payload.id) {
          return item;
        } else {
          return {
            ...item,
            status: payload.status,
            featured: payload.featured,
            deleted: payload.deleted,
            widgetTag: payload.widgetTag,
            thumbnailUrl: payload.thumbnailUrl,
          };
        }
      });

      state.lastUpdated = new Date().toISOString();
    });

    reducersBuilder.addCase(getVideoByIdForSocialVerse.fulfilled, (state, { payload }) => {
      const index = state.items.findIndex((item) => item.id === payload.id);
      if (index !== -1) {
        state.items[index] = payload;
      }
      state.isLoading = false;
    });
    reducersBuilder.addCase(getVideoByIdForSocialVerse.pending, (state, { payload }) => {
      state.isLoading = false;
    });
    reducersBuilder.addCase(getVideoByIdForSocialVerse.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = true;
    });

    reducersBuilder.addCase(getVideoById.fulfilled, (state, { payload }) => {
      state.items = [...state.items, payload];
      state.isLoading = false;
    });
    reducersBuilder.addCase(getVideoById.pending, (state, { payload }) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(getVideoById.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = true;
    });
    reducersBuilder.addCase(getVideoSharesById.fulfilled, (state, { payload }) => {
      state.items = state.items.map((item) => {
        if (item.id !== payload.id) {
          return item;
        } else {
          return {
            ...item,
            sharesAnalyticsData: payload.sharesAnalyticsData,
          };
        }
      });
    });
  },
});

export const {
  goToSelectedPage,
  updateTable,
  removeWidgetTagsFromStories,
  setVideosSorting,
  reset,
  updateSize,
  updateSearch,
  setRefetch,
  resetPage,
  setLoadMore,
} = videosSlice.actions;
export default videosSlice.reducer;
