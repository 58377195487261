import { FC } from 'react';

interface RocketIconProps {
  width?: number;
  height?: number;
}
export const RocketIcon: FC<RocketIconProps> = ({ width = 50, height = 50 }) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        viewBox="0 0 43 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.6743 8.42497C11.2347 13.4087 8.18785 20.5687 7.90493 21.2434L2.98651 19.1323C1.57191 18.523 1.22371 16.6731 2.31185 15.585L8.8625 9.03434C9.88536 8.01148 11.3652 7.55446 12.8016 7.83737L15.6743 8.42497ZM18.917 30.5361C19.5699 31.189 20.5274 31.3631 21.3544 30.9714C23.8789 29.7962 29.2979 27.0323 32.8018 23.5285C42.791 13.5393 42.878 5.39992 42.2904 1.91785C42.1381 1.04733 41.4416 0.350918 40.5711 0.198577C37.0891 -0.389023 28.9497 -0.301972 18.9605 9.68723C15.4567 13.1911 12.7145 18.61 11.5176 21.1345C11.1259 21.9615 11.3217 22.9409 11.9528 23.572L18.917 30.5361ZM34.0858 26.8364C29.1021 31.2761 21.942 34.3229 21.2674 34.6058L23.3784 39.5242C23.9878 40.9388 25.8376 41.287 26.9258 40.1989L33.4764 33.6482C34.4993 32.6254 34.9563 31.1455 34.6734 29.7091L34.0858 26.8364ZM15.1302 32.4948C15.5655 34.8017 14.8038 36.9344 13.3457 38.3926C11.6699 40.0683 6.46858 41.3088 3.09532 41.9617C1.59368 42.2446 0.266136 40.9171 0.549054 39.4154C1.20194 36.0422 2.42067 30.8408 4.11818 29.1651C5.5763 27.707 7.70907 26.9452 10.0159 27.3805C12.5622 27.8593 14.6514 29.9485 15.1302 32.4948ZM23.966 14.1922C23.966 11.7982 25.9247 9.83957 28.3186 9.83957C30.7125 9.83957 32.6712 11.7982 32.6712 14.1922C32.6712 16.5861 30.7125 18.5447 28.3186 18.5447C25.9247 18.5447 23.966 16.5861 23.966 14.1922Z"
          fill="#333745"
        />
      </svg>
    </>
  );
};
