import { KPIValueApiModel } from '../../../api/models/analytics';
import format from 'date-fns/format';
import { theme } from '../../../theme';
// import { theme } from '../../../theme';
// import { ChartEvent, ActiveElement } from 'chart.js';
// import annotationPlugin from 'chartjs-plugin-annotation';

export const options = {
  maintainAspectRatio: false,
  responsive: true,
  animation: false,
  animations: {
    radius: {
      duration: 400,
      easing: 'linear',
      loop: (context: any) => context.active,
    },
  },
  scales: {
    yAxes: [
      {
        gridLines: {
          drawOnChartArea: true,
          color: 'green',
          position: {
            x: '19',
          },
        },
        ticks: {
          beginAtZero: true,
          min: 0,
          precision: 0,
          position: {
            x: '19',
          },
        },
      },
      {
        gridLines: {
          drawOnChartArea: true,
          color: '#EEEEF6',
        },
        ticks: {
          beginAtZero: true,
          min: 0,
          precision: 0,
        },
      },
      {
        gridLines: {
          drawOnChartArea: true,
          color: 'red',
        },
        ticks: {
          beginAtZero: true,
          min: 0,
          precision: 0,
        },
      },
    ],
    xAxes: [
      {
        barThickness: 43,
        gridLines: {
          drawOnChartArea: false,
          color: '#EEEEF6',
        },
        ticks: {
          beginAtZero: true,
          min: 0,
          precision: 0,
        },
      },
    ],
  },
  title: {
    display: false,
  },
  legend: {
    display: false,
  },
  tooltips: {
    xPadding: 9,
    yPadding: 5,
    bodyFontSize: 10,
    bodyFontColor: '#fff',
    backgroundColor: '#181F47',
    displayColors: false,
    callbacks: {
      title: function () {
        return null;
      },
      label: function (tooltipItem: any) {
        return tooltipItem.yLabel.toString();
      },
    },
  },
};
// const DatasetNames = ['Value', 'Live Videos', 'Video Plays', 'pink'];
const getYaxis = (index: number) => {
  const result = {
    id: `y${index + 1}`,
    // display: false,
    gridLines: {
      drawOnChartArea: index === 0, // draw inside chart area
      drawTicks: false, //  no lines extending from ticks
      drawBorder: false, //  no outer axis line
      color: '#D9D9D9', // subtle grid color
      lineWidth: 1,
    },
    ticks: {
      display: false,
      beginAtZero: true,
      min: 0,
      precision: 0,
      position: {
        x: '19',
      },
    },
  };
  return result;
};
const colors = [
  theme.palette.common.GraphColors.green,
  theme.palette.common.GraphColors.blue,
  theme.palette.common.GraphColors.red,
  theme.palette.common.GraphColors.orange,
];

const projectionColors = ['#9AC88E', '#96C5FF', '#EA9191', '#EEDFA8'];

export const createOptions = (
  chartData: KPIValueApiModel[][],
  // selectedXIndex: number | null,
  setSelectedXIndex: (index: number | null) => void,
) => {
  const options = {
    layout: {
      padding: {
        left: 20,
        right: 20,
        top: 10,
        bottom: 30, // 👈 add more if labels are cut off
      },
    },
    maintainAspectRatio: false,
    interaction: {
      mode: 'nearest', // Ensures all points on the same x-axis value are highlighted
      intersect: false, // Prevents the tooltip from only appearing when directly over a point
    },
    responsive: true,
    animation: false,
    animations: {
      radius: {
        duration: 400,
        easing: 'linear',
        loop: (context: any) => context.active,
      },
    },
    scales: {
      yAxes: chartData.map((item, index) => getYaxis(index)),
      xAxes: [
        {
          ticks: {
            display: false, // ❌ hide all default labels
          },
          gridLines: {
            drawOnChartArea: false,
            drawTicks: false,
            drawBorder: false,
          },
        },
      ],
    },
    title: {
      display: false,
    },
    legend: {
      display: false,
    },

    onClick: function (event: any) {
      const chart = this;
      //@ts-ignore
      const xScale = chart.scales['x-axis-0'];
      const { offsetX: x, offsetY: y } = event;

      const xIndex = Math.round(xScale.getValueForPixel(x));
      //@ts-ignore
      if (xIndex < 0 || xIndex >= chart.data.labels.length) return;

      setSelectedXIndex(xIndex);
      //@ts-ignore
      const activeElements = chart.data.datasets.flatMap((_, datasetIndex) => {
        //@ts-ignore
        const meta = chart.getDatasetMeta(datasetIndex);
        const point = meta?.data?.[xIndex];
        return point && point._model && point._model.skip !== true ? [point] : [];
      });
      //@ts-ignore
      chart.tooltip._active = activeElements;
      //@ts-ignore
      chart.tooltip._eventPosition = { x, y }; // ✅ this is the fix
      //@ts-ignore
      chart.tooltip.update(true);
      //@ts-ignore
      chart.draw();
    },
    tooltips: {
      mode: 'x', // Shows tooltips for all datasets at the hovered x-axis value
      intersect: true,
      xPadding: 9,
      yPadding: 5,
      titleFontSize: 10,
      bodyFontSize: 12,
      bodyFontColor: '#fff',
      backgroundColor: '#181F47',
      displayColors: false,
      titleAlign: 'center',
      bodyAlign: 'center',
      footerAlign: 'center',
      titleFontWeight: 'normal',
      bodyFontWeight: 'bold',

      callbacks: {
        title: function (tooltipItem: any) {
          const index = tooltipItem?.[0]?.index;
          const entry = chartData[0]?.[index];

          if (!entry || !entry.date) return '';

          const formattedDate = format(new Date(entry.date), 'PP');
          return formattedDate;
        },
        label: function (tooltipItem: any, data: any) {
          const value = tooltipItem?.value;
          if (value == null) return '';

          const datasetLabel = data.datasets[tooltipItem.datasetIndex]?.label ?? '';
          const baseLabel = datasetLabel; // e.g., "Dataset", "Users", "Revenue"
          return `${baseLabel} ${value}`;
        },
        footer: function (tooltipItem: any) {
          const index = tooltipItem?.[0]?.index;
          const entry = chartData[0]?.[index];

          if (!entry || !entry.date) return '';

          const formattedDate = format(new Date(entry.date), 'PP');
          return formattedDate;
        },
      },
    },
  };

  return options;
};

export const annotationLine = {
  id: 'annotationLine',
  beforeDraw: (chart: any) => {
    if (chart.tooltip._active && chart.tooltip._active.length) {
      const ctx = chart.ctx;
      // ctx.globalCompositeOperation = 'xor';
      const gradient = ctx.createLinearGradient(0, 0, 0, 360);
      gradient.addColorStop(0, `${theme.palette.primary.main}`);
      gradient.addColorStop(0.93, `${theme.palette.primary.main}1a`);
      const { x, y } = chart.tooltip._eventPosition;
      ctx.save();
      ctx.beginPath();
      // ctx.moveTo(x, y);
      ctx.moveTo(x, y);
      ctx.lineTo(x, chart.chartArea.bottom);
      ctx.lineWidth = 2;
      ctx.strokeStyle = gradient;
      // ctx.strokeStyle = '#8868fe';
      ctx.stroke();
      ctx.restore();
    }
  },
};

export const annotationBox = {
  id: 'annotationBox',
  beforeDraw: (chart: any) => {
    const selected = chart.config.options.selectedXIndex ?? null;
    if (selected === null) return;

    const ctx = chart.ctx;
    const xScale = chart.scales['x-axis-0'];
    const x = xScale.getPixelForTick(selected);
    const chartArea = chart.chartArea;

    ctx.save();
    ctx.fillStyle = 'rgba(136, 104, 254, 0.2)'; // Light purple box
    ctx.fillRect(x - 8.5, chartArea.top, 17, chartArea.bottom - chartArea.top); // 17px-wide box
    ctx.restore();
  },
};
interface IChartData {
  dataset: KPIValueApiModel[];
  datasetLabel: string;
}

export const getData = (
  canvas: any,
  chartDataProp: IChartData[],
  option: boolean,
  config: { xAxis: { dateConfig: { format: string } } },
  targetMonths: Date[], // 👈 one per dataset
  isProjected: boolean,
) => {
  const chartDataArray = chartDataProp.map((item) => item.dataset);
  const ctx = canvas.getContext('2d');

  // Generate colors dynamically if needed

  const nonEmptyDatasets = chartDataArray.filter((arr) => arr.length > 0);

  if (nonEmptyDatasets.length === 0) {
    return { labels: [], datasets: [] };
  }

  const referenceData = nonEmptyDatasets.reduce((longest, current) =>
    current.length > longest.length ? current : longest,
  );

  // Determine the labels from the first dataset (all datasets share the same labels)
  const labels = option
    ? referenceData.map((chartEntry) => {
        const formattedChartEntry = chartEntry.date.split('-').join('/');
        const newDate = new Date(formattedChartEntry);
        return format(newDate, config.xAxis.dateConfig.format);
      })
    : getDays(referenceData);

  // Generate datasets dynamically

  //@ts-ignore
  const datasets = chartDataArray.flatMap((chartData, index) => {
    const paddedData = [...chartData];
    while (paddedData.length < referenceData.length) {
      paddedData.push({ date: referenceData[paddedData.length].date, value: null as any });
    }
    const targetMonth = targetMonths?.[index];
    const color = isProjected
      ? projectionColors[index % projectionColors.length]
      : colors[index % colors.length];

    const gradient = ctx.createLinearGradient(0, 0, 0, 360);
    gradient.addColorStop(0, `#f5f6f81a`);
    gradient.addColorStop(0.93, `#f5f6f800`);

    // If no valid targetMonth, return a single dataset (no split)
    if (!targetMonth || paddedData.every((d) => new Date(d.date) < targetMonth)) {
      return [
        {
          elements: {
            line: {
              tension: 0.4,
            },
          },
          label: `${chartDataProp[index].datasetLabel}`,
          data: paddedData.map((d) => d.value),
          backgroundColor: gradient,
          borderColor: color,
          borderWidth: 2,
          pointRadius: 5,
          pointBackgroundColor: color,
          pointBorderColor: color,
          pointBorderWidth: 2,
          pointHoverRadius: 6,
          // pointRadius: 1,
          // pointHoverRadius: 6,
          // pointBackgroundColor: '#fff',
          // pointBorderWidth: 2,
          pointHoverBorderWidth: 4,
          pointHoverBorderColor: color,
          yAxisID: `y${index + 1}`,
        },
      ];
    }

    // Otherwise, split into solid + dashed
    const splitIndex = paddedData.findIndex((entry) => new Date(entry.date) >= targetMonth);
    const before = paddedData.slice(0, splitIndex === -1 ? paddedData.length : splitIndex);
    // const after = paddedData.slice(splitIndex === -1 ? paddedData.length : splitIndex);

    const beforeData = paddedData.map((entry, i) => (i < before.length ? before[i].value : null));

    const afterData = paddedData.map((entry, i) =>
      i >= before.length - 1 ? paddedData[i].value : null,
    );

    return [
      {
        label: `{Dataset} ${index + 1} (solid)`,
        data: beforeData,
        backgroundColor: gradient,
        borderColor: color,
        borderWidth: 2,
        pointRadius: 1,
        pointHoverRadius: 6,
        pointBackgroundColor: '#fff',
        pointHoverBorderColor: color,
        pointHoverBorderWidth: 4,
        pointBorderWidth: 2,
        yAxisID: `y${index + 1}`,
      },
      {
        label: `{Dataset} ${index + 1} (dashed)`,
        data: afterData,
        backgroundColor: gradient,
        borderColor: color,
        borderWidth: 2,
        borderDash: [5, 5],
        pointRadius: 1,
        pointHoverRadius: 6,
        pointBackgroundColor: '#fff',
        pointHoverBorderColor: color,
        pointHoverBorderWidth: 4,
        pointBorderWidth: 2,
        yAxisID: `y${index + 1}`,
      },
    ];
  });

  return { labels, datasets };
};

export const getNormalizedChartData = (chartData: KPIValueApiModel[]) => {
  let accumulatedValue = 0;
  return chartData.map((data) => {
    accumulatedValue += data.value;

    return {
      ...data,
      value: accumulatedValue,
    };
  });
};

export interface MonthValues {
  name: string;
  days: number;
}

export const getMonths = (chartData: KPIValueApiModel[]): MonthValues[] => {
  const months = [] as MonthValues[];
  let iteratedMonth = '';
  let countDays = 0;

  chartData.forEach((chartEntry, index) => {
    const formatMonth = format(new Date(chartEntry.date), 'MMMM');
    if (iteratedMonth && iteratedMonth !== formatMonth) {
      months.push({ name: iteratedMonth, days: countDays });
      countDays = 0;
    }
    iteratedMonth = formatMonth;
    countDays += 1;
    if (chartData.length === index + 1) {
      months.push({ name: iteratedMonth, days: countDays });
    }
  });

  return months;
};
export const getDays = (chartData: KPIValueApiModel[]) => {
  if (chartData) {
    const selectedDate = new Date(chartData[0]!.date.split('-').join('/'));
    const daysNumbers = [
      selectedDate.getDate() - 1,
      selectedDate.getDate(),
      selectedDate.getDate() + 1,
    ];
    return daysNumbers;
  }
};
