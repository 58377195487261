import React from 'react';
import { makeStyles } from '@mui/styles';
import { StatBox } from './StatBox';
import { Grid } from '@mui/material';
import { numberWithCommas } from '../../../services/utilities';
import { PerformanceTotalMetricLabels } from '../../../api/models/performance';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    // gap: 16,
    // flexWrap: 'wrap',
    backgroundColor: '#F7F9FC',
    padding: '16px 16px 0px 16px',
    borderRadius: 8,
    width: '100%',
  },
}));

export const StatsOverview: React.FC<{
  totalStats: { label: string; value: string | number }[];
}> = ({ totalStats }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.container} alignItems="space-between">
      {totalStats.map((stat, i) => (
        <Grid item xs={2} key={i}>
          <StatBox
            key={i}
            label={stat.label}
            value={numberWithCommas(stat.value)}
            showDollarSign={stat.label === PerformanceTotalMetricLabels.SV_VALUE}
          />
        </Grid>
      ))}
    </Grid>
  );
};
