import { createTheme } from '@mui/material';
import { PaletteOptions, Components, ThemeOptions } from '@mui/material/styles';

export const palette: PaletteOptions = {
  primary: {
    main: '#5242EA',
    dark: '#163EC0',
    light: '#D9D4FF',
    contrastText: '#fff',
  },
  //D9D4FF
  secondary: {
    main: '#FFB515',
    dark: '#E5A417',
    light: 'rgba(255, 181, 21, 0.1)',
    contrastText: '#8F94B6',
  },
  background: {
    default: '#FEFEFC',
    paper: '#fff',
  },
  success: {
    main: '#5DD6AA',
    dark: '#3B876B',
    light: '#E3FCF3',
    contrastText: '#fff',
  },
  info: {
    main: '#2196F3',
    dark: '#0B79D0',
    light: '#64B6F7',
    contrastText: '#fff',
  },
  warning: {
    main: '#FF8000',
    dark: '#C76300',
    light: '#FFA347',
    contrastText: '#fff',
  },
  error: {
    main: '#FF6340',
    dark: '#D93916',
    light: '#FF8C73',
    contrastText: '#fff',
  },
  common: {
    Text: {
      secondary: '#8f94b6',
    },
    pageBackground: '#F5F6F8',
    imageSelectorBackground: '#E1E5EE',
    white: '#fff',
    black: '#000',
    gray300: '#D0D5DD',
    gray600: '#475467',
    gray900: '#101828',
    green: '#38B87A',
    lime: '#24B947',
    ContentMenu: {
      published: '#b7ddac',
      unpublished: '#98ceef',
      moderated: '#8d6fcf',
      pending_moderation: '#2196F3',
    },
    GraphColors: {
      green: '#34A853',
      blue: '#0037B7',
      red: '#EA4335',
      orange: '#FFB515',
      lightBlue: '#5C5AEC',
      pink: '#FF85BF',
      mint: '#85D5DA',
    },
    ContentTable: {
      published: '#54d2a5',
      unpublished: '#e6a417',
      moderated: '#d93916',
      pending_moderation: '#2196F3',
    },
    CommonMenu: {
      active: '#b7ddac',
      inactive: '#b4dffa',
    },
    ColoredDots: {
      blue: '#0037B7',
      orange: '#F8A92F',
      lightBlue: '#5C5AEC',
      pink: '#FF85BF',
      mint: '#85D5DA',
    },
    favorite: '#FFB515',
    darkBlue: '#101634',
    deepDark: '#1A1538',
    lightMint: '#A7DFCB',
    slightlyBlueish: '#F6FAFF',
    gold: '#FDB927',
    purple: '#8D6FCF',
    gray: '#8B89A0',
    sidebar: {
      background: '#1A1538',
      accountButton: {
        text: '#FFFFFF',
        detailsText: '#887ee8',
        background: 'rgba(255, 255, 255, 0.1)',
        activeBackground: '#5242EA',
        hoverBackground: '#5242EA4D',
        activeText: '#FFFFFF',
      },
      accountsMenu: {
        background: '#FFFFFF',
        popover: {
          background: '#F5F6F8',
          text: '#667085',
          divider: '#E1E5EE',
        },
        activeButton: {
          background: '#5242EA',
          text: '#FFFFFF',
        },
        inactiveButton: {
          background: 'transparent',
          text: '#667085',
          hoverBackground: '#EAE9F5',
          hoverText: '#667085',
        },
      },
      item: {
        icon: '#555555',
        text: '#FFFFFF',
        activeText: 'rgb(255, 181, 21)',
        activeBackground: 'rgba(255, 181, 21, 0.1)',
      },
    },
    campaignSummary: {
      primaryCampaignBorder: '#F3B03E',
    },
  },

  divider: '#EEEEF6',
  text: {
    primary: '#464c71',
    secondary: '#8F94B6',
  },
};

export const breakpointValues = {
  xs: 0,
  sm: 600,
  md: 768,
  lg: 1280,
  xl: 1920,
};

export const components: Components = {
  MuiCssBaseline: {
    styleOverrides: {
      ':root': {
        /* hopefully the MUI colors */
        '--pageBackgroundColor': '#f5f6f8',
        '--primaryColor': '#2d5af0',
        '--secondaryColor': '#8f94b6',
        '--primaryTextColor': '#464c71',

        '--activeBgr': '#c60f2f',
        '--darkGreyBgr': '#565656',
        '--pageHeaderBgr': '#2a3031',
        '--blackRGBABgr': 'rgba(0, 0, 0, 0.5)',
        '--blackRGBABgrModalVideo': 'rgba(0, 0, 0, 0.9)',
        '--contentBackgroundColor': '#f0f3f8',
        '--sidebarBgr': '#181f47',
        '--sidebarActiveBgr': '#101634',
        '--sidebarIconColor': '#8f94b6',
        '--sidebarActiveIconColor': '#f59f1e',
        '--sidebarSubIconColor': '#b4dffa',
        '--sidebarActiveSubIconColor': '#b7ddac',

        /* Hover */
        '--itemHover': '#d5e2ff',

        /* Shape */
        '--commonBorderRadius': '14px',
        '--selectMenuBorderRadius': '4px',
        '--sectionPadding': '56px',
        '--mobileSectionPadding': '20px',

        /* Error */
        '--error': '#c60f2f',

        /* Common */
        '--formColor': '#010101',
        '--white': '#fff',
        '--black': '#111',
        '--activeSidebarTab': '#c60f2f',
        '--activeColor': '#c60f2f',
        '--darkGreyColor': '#565656',
        '--grey': '#9a9a9a',
        '--lightGrey': '#ededed',
        '--sectionStripeGrey': '#f6f6f6',
        '--titleGrey': '#616161',
        '--textGrey': '#b6b9bc',
        '--lightBlue': '#007aff',
        '--tableBorder': '#dee2e6',
        '--brightGreen': '#06d22f',
        '--tableTextColor': '#050505',
        '--spinnerColor': '#2548e6',
        '--grey300': '#c7c7d9',
        '--darkBlue': '#163ec0',
        '--thinBlue': '#6a8dff',
        '--successDark': '#3b876b',
        '--successLight': '#e3fcf3',

        /* Preview */
        '--iphoneXHeight': '450px',
        '--iphoneXWidth': '200px',
        '--iphoneXborderRadius': '20px',
        '--iponeXMargin': '20px auto',
        '--PreviewSubTitle': '#848484',
        '--BrandDefault': '#4F96FF',
        '--TopgolfBlue': '#0B4C8C',
      },

      html: {
        scrollBehavior: 'smooth',
      },

      body: {
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
        overflowY: 'hidden',
        color: 'var(--black)',
        fontWeight: 600,
      },
    },
  },
  MuiAppBar: {
    styleOverrides: {
      root: {
        zIndex: 'unset',
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      rounded: {
        borderRadius: '8px',
      },
    },
  },
  MuiListItem: {
    styleOverrides: {
      gutters: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        minWidth: 24,
      },
    },
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: 8,
        textTransform: 'none',
        fontSize: '15px',
        fontWeight: 600,
      },
    },
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        borderRadius: 12,
        // border: '1px solid #cacae4',
        // '&:hover:not(.Mui-disabled)': {
        //   borderColor: '#5242EA',
        // },
        border: '1px solid #EEEEF6',
        '&:focus-within fieldset': {
          border: `2px solid #5242EA`,
        },
      },
      notchedOutline: {
        border: '1px solid #cacae4',
      },
      input: {
        height: '24px',
        padding: '9px 16px',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '26px',
        letterSpacing: '0.02em',
      },
      multiline: {
        padding: 0,
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        '&:before': {
          border: '0 !important',
        },
        '&:hover:not(.Mui-disabled):before': {
          border: '0 !important',
        },
        backgroundColor: 'white',
      },
    },
  },
  MuiTablePagination: {
    styleOverrides: {
      actions: {
        color: '#2D5AF0',
      },
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        padding: 'unset',
      },
    },
  },
  MuiList: {
    styleOverrides: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
  },
  MuiStepIcon: {
    styleOverrides: {
      root: {
        '&.Mui-completed': {
          color: 'green',
        },
      },
    },
  },
  MuiTableHead: {
    styleOverrides: {
      root: {
        backgroundColor: '#FFFFFF',
        borderColor: '#FFFFFF',
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        backgroundColor: '#FFFFFF',
        borderColor: '#FFFFFF',
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        backgroundColor: '#FFFFFF',
        borderColor: '#FFFFFF',
      },
    },
  },
  MuiSwitch: {
    styleOverrides: {
      switchBase: {
        color: '#5242EA',
      },
      track: {
        '$checked$checked + &': {
          backgroundColor: '#5242EA',
        },
      },
    },
  },
  MuiRadio: {
    styleOverrides: {
      root: {
        '&.Mui-focusVisible': {
          color: palette!.text!.primary,
        },
      },
    },
  },
};

export const typography = {
  fontFamily: 'Poppins, system-ui, sans-serif',
  h6: {
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '32px',
    color: palette!.text!.primary,
  },
  h3: {
    fontSize: '32px',
    fontWeight: 500,
    lineHeight: '52px',
    color: palette!.text!.primary,
  },
  body2: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '26px',
    color: palette!.text!.primary,
  },
  body1: {
    fontSize: '16px',
    fontWeight: 400,
    color: palette!.text!.primary,
  },
  subtitle2: {
    fontSize: '14px',
    fontWeight: 600,
    color: palette!.text!.primary,
  },
  subtitle1: {
    fontSize: '16px',
    fontWeight: 400,
    color: palette!.text!.primary,
  },
};
export const baseTheme = createTheme({
  palette,
  components,
  typography,
  breakpoints: { values: breakpointValues },
} as ThemeOptions);
