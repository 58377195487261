import React from 'react';
import { makeStyles } from '@mui/styles';
import { Card, Typography, Button, Box } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    borderRadius: 8,
    border: '1px solid #C4C4C4',
    padding: 24,
    textAlign: 'center',
    maxWidth: 300,
    height: '313px',
  },
  iconWrapper: {
    marginBottom: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontWeight: 600,
    fontSize: 16,
    marginBottom: 8,
    color: 'black',
  },
  description: {
    color: '#666',
    fontSize: 14,
    marginBottom: 24,
  },
  button: {
    border: '1px solid #7B61FF',
    color: '#7B61FF',
    textTransform: 'none',
    fontWeight: 600,
    borderRadius: 8,
    padding: '6px 16px',
    position: 'absolute',
    bottom: '10px',
    left: '8%',
    width: '250px',
  },
  checkIcon: {
    color: '#34A853',
    fontSize: 32,
    position: 'absolute',
    bottom: '10px',
    left: '45%',
  },
});

interface PendingTaskCardProps {
  icon: React.ReactNode;
  title: string;
  description: string;
  completed: boolean;
  onStart?: () => void;
  key?: number;
}

export const PendingTaskCard: React.FC<PendingTaskCardProps> = ({
  icon,
  title,
  description,
  completed,
  onStart,
  key,
}) => {
  const classes = useStyles();

  return (
    <Card className={classes.root} elevation={0} key={key}>
      <Box className={classes.iconWrapper}>{icon}</Box>
      <Typography className={classes.title}>{title}</Typography>
      <Typography className={classes.description}>{description}</Typography>
      {completed ? (
        <CheckCircleIcon className={classes.checkIcon} />
      ) : (
        <Button className={classes.button} onClick={onStart}>
          Start Now
        </Button>
      )}
    </Card>
  );
};
