import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    border: '2px solid #8B89A0',
    borderRadius: 999,
    overflow: 'hidden',
    width: '100%',
    maxWidth: 360,
  },
  text: {
    fontSize: '14',
    fontWeight: 500,
    color: '#8B89A0',
  },
  selectedText: {
    color: '#5242EA',
    fontWeight: 600,
    fontSize: '14',
  },
  option: {
    flex: 1,
    textAlign: 'center',
    padding: '6px 0',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    fontWeight: 500,
    fontSize: 14,
    color: '#9E9E9E',
    borderRight: '2px solid #BDBDBD',
    backgroundColor: 'transparent',
    '&:last-child': {
      borderRight: 'none',
    },
  },
  selected: {
    backgroundColor: '#F5F2FF',
    color: '#7B61FF',
    borderRight: '2px solid #7B61FF',
    '&:last-child': {
      borderRight: 'none',
    },
  },
  disabled: {
    color: '#BDBDBD',
    backgroundColor: '#F9F9F9',
    pointerEvents: 'none',
    cursor: 'not-allowed',
  },
});

// 👇 Generic type T extends string
interface ToggleOption<T extends string> {
  key: T;
  label: string;
  disabled?: boolean;
}

interface ToggleSwitchProps<T extends string> {
  options: ToggleOption<T>[];
  selectedKey: T;
  onChange: (key: T) => void;
}

function ToggleSwitch<T extends string>({ options, selectedKey, onChange }: ToggleSwitchProps<T>) {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {options.map((option) => {
        const isSelected = selectedKey === option.key;
        const isDisabled = option.disabled;
        return (
          <Box
            key={option.key}
            className={`${classes.option} ${isSelected ? classes.selected : ''} ${
              isDisabled ? classes.disabled : ''
            }`}
            onClick={() => onChange(option.key)}
          >
            <Typography className={isSelected ? classes.selectedText : classes.text}>
              {option.label}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
}

export default ToggleSwitch;
