import { FC, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Box } from '@mui/material';
import { KPIValueApiModel, KPIApiNames } from '../../../api/models/analytics';
// import { KPIDTOActiveAnalyticsBlock } from '../../../store/storeModels';

import { useWindowResize } from '../../../services/hooks/useWindowResize';
import {
  getData,
  // options,
  getNormalizedChartData,
  createOptions,
  annotationLine,
  // annotationBox,
} from './ChartSection.helper';
import { LayoutMode } from '../../../common/constants/constants';
// import { useTypedSelector } from '../../../store';

import { Spinner } from '../../../common/assets/Spinner';
import { ModalChart } from './ModalChart';

import {
  Chart,
  ChartContainer,
  ChartWrapper,
  ChartLeftLabel,
  ChartComponentStyled,
} from './ChartSection.style';
// import { pageLoadsKpi, uniqueVisitorsKPI, videoPlaysKpi } from './TestData';
import { BottomChartSection } from '../Components/BottomChartSection';
import { formatMonthYear } from '../../../services/utilities';
import { TopChartSection } from '../Components/TopChartSection';
import { PendingTasks } from '../Components/PendingTasks';
import ToggleSwitch from '../../../common/components/CustomSwitch/CustomSwitch';

interface ChartSectionProps {
  chartData: {
    dataset: KPIValueApiModel[];
    datasetLabel: string;
  }[];
  projectedChartData: {
    dataset: KPIValueApiModel[];
    datasetLabel: string;
  }[];
  isChart: boolean;
  isLoading: boolean;
  onClose: () => void;
  onClickChartAnalyticsBlock: (kpiName: KPIApiNames) => void;
  // activeAnalyticsBlock: KPIDTOActiveAnalyticsBlock;
  // kpis: KPIApiModel;
  children: ReactNode;
  offsetKPIValues?: number;
  showPendingTasks?: boolean;
}
enum ChartSectionStrings {
  actual = 'Actual Results',
  projected = 'Projected',
}
export enum results {
  actual = 'actual',
  projected = 'projected',
}
export const ChartSection: FC<ChartSectionProps> = ({
  chartData: charDataProp,
  projectedChartData,
  isChart,
  isLoading,
  onClose,
  // activeAnalyticsBlock,
  children,
  showPendingTasks,
  // offsetKPIValues,
}) => {
  const device = useWindowResize();
  // const { isCummulativeGraph } = useTypedSelector((state) => state.ui);
  // value, live videos, page load,  visitors,

  const options = [
    { key: results.actual, label: ChartSectionStrings.actual },
    { key: results.projected, label: ChartSectionStrings.projected, disabled: false },
  ];

  const [selected, setSelected] = useState<results>(results.actual);
  const isProjetedResults = selected === results.projected;
  const isCummulativeGraph = false;
  const chartWrapper = useRef<HTMLDivElement>(null);
  const lineref = useRef<any>();
  const [selectedXIndex, setSelectedXIndex] = useState<number | null>(0);
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  // console.log('lineref', lineref);
  // console.log('hoveredData', hoveredData);
  const selectedXRef = useRef<number | null>(selectedXIndex);
  selectedXRef.current = selectedXIndex;

  const chartData = isProjetedResults ? projectedChartData : charDataProp;
  const chartDataWithouLabel = chartData.map((item) => item.dataset);

  useEffect(() => {
    const id = setTimeout(() => {
      setIsFirstLoading(false);
    }, 1000);
    return () => clearTimeout(id);
  }, []);

  const lineChartOptions = useMemo(
    () => createOptions(chartDataWithouLabel, setSelectedXIndex),

    [chartDataWithouLabel],
  );
  // console.log('selectedXIndex 2', selectedXIndex);
  const highlightXAxisLabel = useMemo(
    () => ({
      id: 'highlightXAxisLabel',
      afterDatasetsDraw: (chart: any) => {
        const xAxis = chart.scales['x-axis-0'];
        const ctx = chart.ctx;
        const labels = chart.data.labels || [];

        const selected = selectedXRef.current;
        const fontSize = 12;
        const padding = 6;
        const boxHeight = 18;
        const boxY = xAxis.bottom + 6;

        ctx.save();
        ctx.textAlign = 'center';
        ctx.textBaseline = 'top';
        ctx.font = `${fontSize}px sans-serif`;

        labels.forEach((label: string, i: number) => {
          const x = xAxis.getPixelForTick(i);

          if (i === selected) {
            // Highlighted background
            const textWidth = ctx.measureText(label).width;
            ctx.fillStyle = '#e0dfff';
            ctx.fillRect(x - textWidth / 2 - padding, boxY, textWidth + padding * 2, boxHeight);

            // Bold label
            ctx.fillStyle = '#000';
            ctx.font = `bold ${fontSize}px sans-serif`;
          } else {
            ctx.fillStyle = '#888';
            ctx.font = `${fontSize}px sans-serif`;
          }

          ctx.fillText(label, x, boxY + 2);
        });

        ctx.restore();
      },
    }),
    [],
  );

  const annotationBox = useMemo(
    () => ({
      id: 'annotationBox',
      beforeDraw: (chart: any) => {
        const selected = selectedXRef.current;
        // console.log('selectedXRef inside plugin', selected);
        if (selected !== null) {
          const ctx = chart.ctx;
          const xScale = chart.scales['x-axis-0'];
          const x = xScale.getPixelForTick(selected);
          const chartArea = chart.chartArea;

          ctx.save();
          ctx.fillStyle = 'rgba(136, 104, 254, 0.2)';
          ctx.fillRect(x - 8.5, chartArea.top, 17, chartArea.bottom - chartArea.top);
          ctx.restore();
        }
      },
    }),
    [],
  );

  // useEffect(() => {
  //   if (chartWrapper && chartWrapper.current) {
  //     const element = chartWrapper.current as HTMLDivElement;
  //     const width = element?.offsetWidth;
  //     setChartWidth(width);
  //   }
  // }, [chartWrapper]);

  // console.log('offsetKPIValues', offsetKPIValues);

  const normalizeData = (data: KPIValueApiModel[], offsetKPIValues: number | undefined) => {
    let chartDataOffset: KPIValueApiModel[];
    if (data.length && offsetKPIValues) {
      chartDataOffset = data.map((item, index) => {
        return index !== 0
          ? item
          : {
              ...item,
              value: item.value + offsetKPIValues,
            };
      });
      return getNormalizedChartData(chartDataOffset);
    }
    return getNormalizedChartData(data);
  };
  //pageLoadsKpi, videoPlaysKpi, uniqueVisitorsKPI
  // const normalizedChartData = useMemo(() => normalizeData(pageLoadsKpi, 0), [pageLoadsKpi]);

  // const normalizedClipsKpis = useMemo(() => normalizeData(videoPlaysKpi, 0), [videoPlaysKpi, 0]);
  // const normalizedOtherKpis = useMemo(() => normalizeData(uniqueVisitorsKPI, 0), [
  //   uniqueVisitorsKPI,
  //   0,
  // ]);
  //'dd'
  const dataConfig = {
    xAxis: {
      dateConfig: {
        format: 'MMM-yy',
      },
    },
  };
  // const cumulativeDatasets = [normalizedClipsKpis, normalizedChartData, normalizedOtherKpis];
  // const normalDatasets = [pageLoadsKpi, videoPlaysKpi, uniqueVisitorsKPI];
  // const normalDatasetsWithLabels = [
  //   { dataset: pageLoadsKpi, datasetLabel: 'Page Loads' },
  //   { dataset: videoPlaysKpi, datasetLabel: 'Video Plays' },
  //   { dataset: uniqueVisitorsKPI, datasetLabel: 'Unique Visitors' },
  // ];

  const cumulativeDatasets = chartData.map((item) => {
    return { ...item, dataset: normalizeData(item.dataset, 0) };
  });
  // const normalDatasets = [pageLoadsKpi, videoPlaysKpi, uniqueVisitorsKPI];
  const normalDatasetsWithLabels = chartData;

  const getDateAtSelectedIndex = useMemo(() => {
    if (!(typeof selectedXIndex === 'number' && normalDatasetsWithLabels.length)) return '';
    return normalDatasetsWithLabels[0]?.dataset[selectedXIndex]?.date || '';
  }, [selectedXIndex, normalDatasetsWithLabels]);

  // const showPendingTasks = true;

  // FUNCTION: getMonthValuesAtSelectedIndex
  // ASSUMPTION: NORMALDATASET HAS UNIFORM VALUES.
  // TODO: REQUIRED UPDATES FOR MISSING VALUES AT INDEX/DATE, AVOID MEMORY OVERFLOW

  const showChart = isProjetedResults || !showPendingTasks;

  const getMonthValuesAtSelectedIndex = useMemo(() => {
    if (!(typeof selectedXIndex === 'number' && normalDatasetsWithLabels.length)) return [];
    return normalDatasetsWithLabels.map((dataset) => {
      return {
        value: dataset.dataset[selectedXIndex]?.value?.toString() || '',
        label: dataset.datasetLabel,
      };
    });
  }, [selectedXIndex, normalDatasetsWithLabels]);

  if ((isLoading || isFirstLoading) && device !== LayoutMode.Mobile) {
    return (
      <Box bgcolor="#fff" borderRadius="8px 0 0 8px" height="calc(100vh - 80px)">
        <Spinner color="var(--lightBlue)" />
      </Box>
    );
  }

  const ChartBlock = (
    <ChartContainer
      style={{
        zoom: '1.11',
      }}
    >
      <ChartWrapper>
        <ChartLeftLabel>
          {/* {isHealthCare && activeAnalyticsBlock.mainName === 'Creators'
            ? CommonStrings.Patients
            : activeAnalyticsBlock.mainName || DashboardStrings.ChartLeftLabel} */}
        </ChartLeftLabel>
        <Chart ref={chartWrapper}>
          <>
            <Line
              data={(canvas: any) =>
                getData(
                  canvas,
                  isCummulativeGraph ? cumulativeDatasets : normalDatasetsWithLabels,
                  true,
                  dataConfig,

                  [
                    // new Date('2024-10-01'), // target for dataset 1
                    // new Date('2024-10-01'), // target for dataset 2
                    // new Date('2024-10-01'), // target for dataset 3
                  ],
                  isProjetedResults,
                )
              }
              options={lineChartOptions}
              plugins={[annotationLine, annotationBox, highlightXAxisLabel]}
              ref={lineref}
            />
          </>
        </Chart>
      </ChartWrapper>
    </ChartContainer>
  );

  const ChartBlockHandler =
    device === LayoutMode.Mobile ? (
      <ModalChart isLoading={true} isChart={isChart} onClose={onClose} title={'title'}>
        {ChartBlock}
      </ModalChart>
    ) : (
      ChartBlock
    );

  return (
    <ChartComponentStyled>
      {children}

      <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
        <ToggleSwitch<results> options={options} selectedKey={selected} onChange={setSelected} />
      </div>
      <TopChartSection title={!showChart ? '' : formatMonthYear(getDateAtSelectedIndex)} />
      {!showChart ? (
        <Box>
          <PendingTasks />
        </Box>
      ) : (
        <>
          {ChartBlockHandler}
          <Box>
            {/* <div>
          <div>
            <p>{hoveredData?.datasetLabel || ''}</p>
            <p>{hoveredData?.label || ''}</p>
            <p>{hoveredData?.value || ''}</p>
          </div>
        </div> */}
            <BottomChartSection
              stats={getMonthValuesAtSelectedIndex}
              isProjected={isProjetedResults}
            />
          </Box>
        </>
      )}
    </ChartComponentStyled>
  );
};
