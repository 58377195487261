import { FC } from 'react';

interface QRCodeIconProps {
  width?: number;
  height?: number;
}

export const QRCodeIcon: FC<QRCodeIconProps> = ({ width = 24, height = 24 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path d="M8.15071 8.123C8.01543 7.98772 7.82784 7.91016 7.63634 7.91016C7.44484 7.91016 7.25718 7.98772 7.1219 8.123C6.98662 8.25828 6.90906 8.44591 6.90906 8.63744C6.90906 8.82897 6.98662 9.01609 7.1219 9.15188C7.25762 9.28716 7.44481 9.36472 7.63634 9.36472C7.82787 9.36472 8.015 9.28716 8.15071 9.15188C8.286 9.01613 8.36362 8.82897 8.36362 8.63744C8.36362 8.44591 8.28596 8.25875 8.15071 8.123Z" />
      <path d="M10.5455 5H4.72728C4.32563 5 4 5.32563 4 5.72728V11.5455C4 11.9471 4.32563 12.2727 4.72728 12.2727H10.5455C10.9471 12.2727 11.2727 11.9471 11.2727 11.5455V5.72728C11.2727 5.32563 10.9471 5 10.5455 5ZM9.81819 10.8182H5.45453V6.45453H9.81816V10.8182H9.81819Z" />
      <path d="M16.878 8.123C16.7423 7.98772 16.5552 7.91016 16.3636 7.91016C16.1721 7.91016 15.9845 7.98772 15.8492 8.123C15.7139 8.25875 15.6364 8.44591 15.6364 8.63744C15.6364 8.82897 15.7139 9.01609 15.8492 9.15188C15.9845 9.28716 16.1721 9.36472 16.3636 9.36472C16.5552 9.36472 16.7423 9.28716 16.878 9.15188C17.0133 9.01613 17.0909 8.82897 17.0909 8.63744C17.0909 8.44591 17.0133 8.25875 16.878 8.123Z" />
      <path d="M19.2728 5H13.4546C13.0529 5 12.7273 5.32563 12.7273 5.72728V11.5455C12.7273 11.9471 13.0529 12.2727 13.4546 12.2727H19.2728C19.6744 12.2727 20 11.9471 20 11.5455V5.72728C20 5.32563 19.6744 5 19.2728 5ZM18.5455 10.8182H14.1819V6.45453H18.5455V10.8182H18.5455Z" />
      <path d="M8.15071 16.8496C8.015 16.7143 7.82784 16.6367 7.63634 16.6367C7.44484 16.6367 7.25762 16.7143 7.1219 16.8496C6.98662 16.9853 6.90906 17.1725 6.90906 17.364C6.90906 17.5555 6.98662 17.7432 7.1219 17.8784C7.25762 18.0137 7.44481 18.0913 7.63634 18.0913C7.82787 18.0913 8.015 18.0137 8.15071 17.8784C8.286 17.7427 8.36362 17.5555 8.36362 17.364C8.36359 17.1725 8.28596 16.9853 8.15071 16.8496Z" />
      <path d="M10.5455 13.7266H4.72728C4.32563 13.7266 4 14.0522 4 14.4538V20.272C4 20.6737 4.32563 20.9993 4.72728 20.9993H10.5455C10.9471 20.9993 11.2727 20.6737 11.2727 20.272V14.4538C11.2727 14.0522 10.9471 13.7266 10.5455 13.7266ZM9.81819 19.5447H5.45453V15.1811H9.81816V19.5447H9.81819Z" />
      <path d="M16.8485 16.6367H16.3636C15.962 16.6367 15.6364 16.9623 15.6364 17.364C15.6364 17.7657 15.962 18.0913 16.3636 18.0913H16.8485C17.2501 18.0913 17.5758 17.7657 17.5758 17.364C17.5758 16.9623 17.2501 16.6367 16.8485 16.6367Z" />
      <path d="M19.2727 18.0898C18.8711 18.0898 18.5455 18.4155 18.5455 18.8171V19.5444H16.8485C16.4468 19.5444 16.1212 19.87 16.1212 20.2717C16.1212 20.6733 16.4468 20.999 16.8485 20.999H19.2727C19.6744 20.9989 20 20.6733 20 20.2717V18.8171C20 18.4155 19.6744 18.0898 19.2727 18.0898Z" />
      <path d="M13.4546 16.6367C13.0529 16.6367 12.7273 16.9623 12.7273 17.364V18.3337C12.7273 18.7353 13.0529 19.061 13.4546 19.061C13.8562 19.061 14.1819 18.7353 14.1819 18.3337V17.364C14.1819 16.9623 13.8562 16.6367 13.4546 16.6367Z" />
      <path d="M16.8484 13.7266H15.3939C14.9923 13.7266 14.6666 14.0522 14.6666 14.4538C14.6666 14.8555 14.9923 15.1811 15.3939 15.1811H16.8484C17.2501 15.1811 17.5757 14.8555 17.5757 14.4538C17.5757 14.0522 17.2501 13.7266 16.8484 13.7266Z" />
    </g>
  </svg>
);
