import React from 'react';
import { makeStyles } from '@mui/styles';

interface StatBoxProps {
  label: string;
  value: string | number;
  showDollarSign?: boolean;
}

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#F7F9FC', // light gray background
    borderRadius: 8,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
    height: '80px',
    // padding: '12px 16px',
    // minWidth: 120,
  },
  label: {
    position: 'absolute',
    top: '-30px',
    fontSize: '0.875rem',
    fontWeight: 600,
    color: '#475467',
    marginBottom: 4,
    textAlign: 'center',
    width: '100%',
    whiteSpace: 'nowrap',
  },
  value: {
    position: 'relative',
    fontSize: '1.25rem',
    fontWeight: 500,
    color: '#475467', // darker text
    width: '144px',
    background: 'white',
    textAlign: 'center',
    border: '1px solid #DCDBE4',
    borderRadius: '6px',
    // height:''
  },
}));

export const StatBox: React.FC<StatBoxProps> = ({ label, value, showDollarSign = false }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.value}>
        <div className={classes.label}>{label}</div>
        <p>
          {showDollarSign ? '$' : ''}
          {value}
        </p>
      </div>
    </div>
  );
};
